import React, {useState} from 'react';
import loadmoreIcon from './../PerformanceListing/load-more-icon.png';
import './BlogListing.scss';

const BlogListing = ({title, blogPosts, defaultDisplay = 2}) => {
    const [display, setDisplay] = useState(defaultDisplay)
    const handleShowMore = () =>  {
        setDisplay(display + defaultDisplay);
    }
    
    return <section className="blog-listing container">
        <h2 className="blog-title">{title}</h2>
        <div className="blog-list">
            {!!blogPosts && blogPosts.map((blog, i) => display > i && <div className="blog-item" key={i}>
                {!!blog.fields.featureImage && <div className="item-image" style={{backgroundImage: `url('${blog.fields.featureImage.url}')`}} />}
                <div className="item-content">
                    <h3 className="item-title">{blog.fields.title}</h3>
                    <p className="item-excerpt">{blog.fields.excerpt}</p>
                    <p className="item-phone"><b>Phone: </b> <a href={`tel:${blog.fields.phone}`}>{blog.fields.phone}</a></p>
                    <a href={blog.fields.path}>View Offer</a>
                </div>
            </div>)}
        </div>
        {display != blogPosts.length && <div className="loadmore container">
            <button onClick={handleShowMore}>
                LOAD MORE
                <img src={loadmoreIcon} alt="load more" />
            </button>
        </div>}
    </section>
}

export default BlogListing;