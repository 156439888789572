import React, { useEffect } from 'react';
import './PerformanceDetails.scss';
import Image from './../Image/Image';
import TextWithAsideRuntime from './../TextWithAsideRuntime/TextWithAsideRuntime';
import TextWithAside from './../TextWithAside/TextWithAside';
import PerformanceSlider from './../PerformanceSlider/PerformanceSlider';
import Performers from './../Performers/Performers';
import PerformanceDateSelectModal from './../PerformanceDateSelectModal/PerformanceDateSelectModal';
import moment from 'moment';
import constants from './../../helpers/constants';

const PerformanceDetails = (props) => {

    useEffect(() => {
        toggleBuyTicketModal();
        window.addEventListener("hashchange", toggleBuyTicketModal);
        function toggleBuyTicketModal() {
            if (window.location.hash.slice(1) == "selectDates") {
                document.body.classList.add('buyTicketModalOpen');
                // if (!!props.tessitura.season && props.tessitura.season.length == 1) {
                //     window.open(`${constants.ArvadaSupportUrl}/${props.seasonID}/${props.tessitura.season[0].Id}?z=1`)
                //     document.body.classList.remove('buyTicketModalOpen');
                //     history.back();
                // }
            } else {
                document.body.classList.remove('buyTicketModalOpen');
            }
        }
    })
    

    // Demensions of Photos Gallery
    let mediaPhotos = {
        desktopSlideWidth: 620,
        desktopSlideHeight: 400,
        mobileSlideWidth: 300,
        mobileSlideHeight: 193
    }

    let videoIframes = {
        desktopSlideWidth: 300,
        desktopSlideHeight: 170,
        mobileSlideWidth: 300,
        mobileSlideHeight: 170
    }

    let _startDate = props.startDate.split('T')[0];
    let _endDate = props.endDate.split('T')[0]; 

    let bottomJumplinkPerformance = {
        title: props.title,
        date: `${moment(_startDate).format('MMMM DD')} - ${moment(_endDate).format('MMMM DD, YYYY',)}`,
        link: !!props.seasonID ? { href: '#selectDates', target: '_self', text: 'Buy Tickets' } : false
    }
    return (
        <section className="performance-detail">
            <section id={!!props.jumplinks && props.jumplinks.length > 0 && props.jumplinks[0].uID}>
                <TextWithAsideRuntime 
                    content={props.overview} 
                    aside={props.overviewAside} 
                    runtimeAsMinutes={props.runTime} 
                    runtimeDescription={props.runTimeNote} 
                    links={props.jumplinks} 
                    breadcrumb={props.breadcrumb}
                    bottomJumplinkPerformance={bottomJumplinkPerformance}
                    disableRuntimeLabel={props.disableRuntimeLabel}
                    />
            </section>
            {!!props.details && <TextWithAside content={props.details} aside={props.detailsAside} className="container mt-100" />}

            {!!props.images && !!props.images.media && props.images.media.length > 0 && !!props.jumplinks && !!props.jumplinks[1] && <section id={props.jumplinks[1].uID} className="photo">
                <h1 className="container h1">{ props.jumplinks[1].text }</h1>
                <PerformanceSlider fallbackAlt={props.title} items={props.images.media} display={1} dimensions={mediaPhotos} />
            </section>}

            {!!props.featuredImage && <section className="featuredImage">
                {/* <img src={props.featuredImage.url} /> */}
                <Image fullsize={true} url={props.featuredImage.url} />
            </section>}

            {!!props.videos && !!props.videos.items && !!props.videos.items.length > 0 && !!props.jumplinks && !!props.jumplinks[2] && (<section id={props.jumplinks[2].uID} className="video">
                <h1 className="container h1">{ props.jumplinks[2].text }</h1>
                <PerformanceSlider fallbackAlt={props.title} items={props.videos.items} display={2} dimensions={videoIframes} isImage={false}/>
            </section>)}

            {!!props.performers && !!props.performers.items && !!props.performers.items.length > 0 && !!props.jumplinks && !!props.jumplinks[3] && <section id={props.jumplinks[3].uID} className="cast">
                <h1 className="container h1">{ props.jumplinks[3].text }</h1>
                <Performers items={props.performers.items}/>
            </section>}
            
            {!!props.additionalDetails && <section id={!!props.jumplinks && !!props.jumplinks[4] && props.jumplinks[4].uID}>
                <h1 className="container h1">{ props.jumplinks[4].text }</h1>
                <TextWithAside content={props.additionalDetails} aside={props.additionalDetailsAside} className="container additional" />
            </section>}

            <PerformanceDateSelectModal {...props}/>

        </section>
    );
}

export default PerformanceDetails;