import React from 'react';
import './Button.scss';

const Button = ({ url, asLink = true, className, onClick }) => {
    const buttonHTML = asLink ? (
        <a className={`button ${className}`} href={!!url && url.href.replace("~","")} target={!!url && url.target} onClick={onClick}>
            {!!url && url.text}
        </a>
    ) : (
        <div role="button" className={`button ${className}`} onClick={onClick}>
            {!!url && url.text}
        </div>
    );
    return className === 'container' ? <div className="container">{buttonHTML}</div> : buttonHTML;
};

export default Button;
