import React, { useEffect } from 'react';
import './PerformersModal.scss';

const PerformersModal = ({ data, display, onDismiss }) => {
    if (data == '') {
        return <React.Fragment></React.Fragment>;
    }
    const handleClose = () => {
        let gradient = document.getElementsByClassName('modal-gradient')[0];
        let modalContent = document.getElementsByClassName('modalContent')[0];
        let modalArticle = document.getElementsByClassName('modalArticle')[0];
        gradient.classList.remove('no-opacity');
        modalContent.classList.remove('modalContentWidth');
        modalArticle.classList.remove('opacity-1');
        onDismiss(false);
    };

    useEffect(() => {
        if (display) {
            let gradient = document.getElementsByClassName('modal-gradient')[0];
            let modalContent = document.getElementsByClassName('modalContent')[0];
            let modalArticle = document.getElementsByClassName('modalArticle')[0];
            gradient.className = gradient.className.concat(' no-opacity');
            modalContent.className = modalContent.className.concat(' modalContentWidth');

            // Waiting for panel is loaded ready to display text
            setTimeout(() => {
                modalArticle.className = modalArticle.className.concat(' opacity-1');
            }, 500);

            document.querySelector('body').style.overflow = 'hidden';
        } else {
            document.querySelector('body').style.overflow = 'visible';
        }
    }, [display]);

    return (
        <div className={`performersModal ${display ? 'show' : ''}`} role="dialog">
            <div className="modal-gradient"></div>
            <div className="modalContent">
                <div className="modalImage">
                    {!!data.fields.picture && <img src={data.fields.picture.url} alt={data.fields.picture.label} />}
                </div>
                <article className="modalArticle">
                    <h2 className="h3">{data.fields.name}</h2>
                    <p>{data.fields.description}</p>
                </article>
                <span className="closeButton" onClick={handleClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                        <path
                            fill="currentColor"
                            d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                        ></path>
                    </svg>
                </span>
            </div>
        </div>
    );
};

export default PerformersModal;
