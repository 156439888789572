import './App.scss';
import Jumplinks from './Jumplinks/Jumplinks';
import SiteHeader from './SiteHeader/SiteHeader';
import SiteFooter from './SiteFooter/SiteFooter';
import ZoneRenderer from './ZoneRenderer';

import React from 'react';
const App = ({zones, currentPage, sitemap, thisSiteMapItem, api, globalSiteSettings, prefix, TNEWTemplate}) => {
  return (<React.Fragment>
    
    <SiteHeader prefix={prefix} globalSiteSettings={globalSiteSettings} sitemap={sitemap} />
    
    {!!zones && !!zones.Masthead && <ZoneRenderer zone={zones.Masthead} zoneName={"Masthead"} />}
    
    {!!currentPage && !!currentPage.jumpLinks && <Jumplinks links={currentPage.jumpLinks} />}
    
    {!!zones && !!zones.Main && <ZoneRenderer zone={zones.Main} zoneName={"Main"} /> }

    <SiteFooter globalSiteSettings={globalSiteSettings} sitemap={sitemap} />
  
    </React.Fragment>);
}

export default App;
