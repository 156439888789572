import React from 'react';
import PerformanceSlider from './../PerformanceSlider/PerformanceSlider';
import './ImageGallery.scss';

const ImageGallery = (props) => {
    let mediaPhotos = {
        desktopSlideWidth: 620,
        desktopSlideHeight: 400,
        mobileSlideWidth: 300,
        mobileSlideHeight: 193
    }
    return (
        <section className="imageGallery">
            <div className="container">
                <h2 className="h2">{props.title}</h2>
            </div>
            <PerformanceSlider items={props.images.media} display={1} dimensions={mediaPhotos} />
        </section>
    )
}

export default ImageGallery;