import React, { useState, useEffect } from 'react';

import './SiteWideAlert.scss';

import RichTextArea from './../RichTextArea/RichTextArea';
import closeIcon from './close-icon-white.png';

const SiteWideAlert = ({ alert }) => {
    const [display, setDisplay] = useState(true);

    return (
        alert &&
        display ? (
            <div className="site-wide-alert">
                <div className="wide-container container">
                    <RichTextArea textblob={alert} />
                    <button className="site-wide-alert-dismiss" onClick={() => setDisplay(false)}>
                        <img className="" src={closeIcon} alt="close alert" />
                    </button>
                </div>
            </div>
        ) : <div></div>
    );
};

export default SiteWideAlert;
