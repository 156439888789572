import React from 'react';
import RichTextArea from '../RichTextArea/RichTextArea';

import './TextWithImage.scss';

const TextWithImage = ({className, image, content, orientationOnDesktop }) => {
    return (
        <div className={className}>
            <article className={`textWithImage has-${!!image ? 'image': 'no-image'} has-${!!content ? 'content': 'no-content'}  image-on-${orientationOnDesktop}`}>
                {!!image && <div className="textWithImage-image">
                    <img src={`${image.url}?w=640`} alt={image.label || ''} />
                </div>}
                {!!content && <main className="textWithImage-text">
                    <RichTextArea textblob={content} />
                </main>}
            </article>
        </div>
    );
};

export default TextWithImage;
