import React from 'react';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import PerformanceFilter from './../PerformanceFilter/PerformanceFilter';
import PerformanceItem from './../PerformanceItem/PerformanceItem';
import loadmoreIcon from './../PerformanceListing/load-more-icon.png';
import './../PerformanceListing/PerformanceListing.scss';
import './EducationListing.scss';
import constants from './../../helpers/constants';
import loading from './../SiteNav/loading.gif';

function chunkArray(myArray, chunk_size){
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];
    
    for (index = 0; index < arrayLength; index += chunk_size) {
        var myChunk = myArray.slice(index, index+chunk_size);
        // Do something if you want with the group
        tempArray.push(myChunk);
    }

    return tempArray;
}

class EducationListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productionSeasons: [],
            keywords: [],
            selectedKeywords: [],
            showValue: 200,
            searchText: ''
        }
        this.getData = this.getData.bind(this)
    }

    applyFilters = (selectedKeywords) => {
        const x = selectedKeywords.map( j => parseInt(j));
        this.setState({selectedKeywords: x})
    }

    getData = async () => {
        const today = new Date();
        const PerformanceStartDate = today.toISOString();
        let productions = await axios.get(`/api/performances/search?keywordids=${this.props.keywordIds}&performancestartdate=${PerformanceStartDate}`);
        let productionSeasons = await axios.get(`/api/productionseasons/search?keywordids=${this.props.keywordIds}&performancestartdate=${PerformanceStartDate}`);
        
        let productionElementIds = productions.data.data.map( p => p.PerformanceId);
        let productionSeasonElementIds = productionSeasons.data.data.map( p => p.ProductionSeasonId);
        let productionElementIdsChunked = chunkArray(productionSeasonElementIds.concat(productionElementIds), 100);

        let keywordspromiseArray = productionElementIdsChunked.map( productionElementIds=> axios.get(`/api/keywords?productionElementIds=${productionElementIds.join(',')}`) );
        let keywords = await Promise.all( keywordspromiseArray );
        
        keywords = _.flattenDeep(keywords.map( k => k.data.data));
        
        productionSeasons = productionSeasons.data.data;
        productions = productions.data.data.map( production => {
            let _keywords = keywords.filter( keyword => keyword.RequestedOwner.ElementId == production.PerformanceId)
                                .map( keyword => keyword.Keywords )[0]
            return {
                ...production, 
                keywords: _keywords,
                keywordIds: _keywords.map(k => k.Id)
            }
        });
        productionSeasons = productionSeasons.map(season => { 
            
            let _productions = productions.filter(production => production.ProductionSeason.Id == season.ProductionSeasonId);

            _productions = _productions.map((singleProduction) => {
                const performanceId = singleProduction.PerformanceId;
                return ({
                    production: singleProduction
                });
            });
            
            let _keywords = keywords.filter( keyword => keyword.RequestedOwner.ElementId == season.PerformanceId)
                                .map( keyword => keyword.Keywords )[0];
                                                                
            return { 
            ...season,
                keywords: _keywords,
                // webcontent: _webcontent,
                productions : _productions,
                productionsKeywordIds: _.flattenDeep(_productions.map(p => p.keywordIds))
            } 
        });

        keywords = _.uniqBy(_.flattenDeep(keywords.map( k => k.Keywords )), k => k.Id).map(k => k);

        this.setState({ 
            productionSeasons: productionSeasons,
            keywords: keywords,
            productions: productions
        });
    }

    getDataOld = async () => {
        let res = await axios.get(`/api/listingsbykeywords?keywordIds=${this.props.keywordIds}`);
    
        let {productionSeasons, productions, keywords, webcontent} = res.data;

        productions = productions.map( production => {
            let _keywords = keywords.filter( keyword => keyword.RequestedOwner.ElementId == production.PerformanceId)
                                .map( keyword => keyword.Keywords )[0]
            return {
                ...production, 
                keywords: _keywords,
                webcontent: webcontent.filter( content => content.RequestedOwner.ElementId == production.PerformanceId)
                                    .map( content => content.WebContents)[0],
                keywordIds: _keywords.map(k => k.Id)
            }
        })

        const getWebContent = (performanceId) => {
            let finalWebContentItem = [];
            webcontent.forEach((contentItem) => {
                contentItem.WebContents.forEach((content) => {
                    if (content.Owner.ElementId == performanceId) {
                        finalWebContentItem.push(content);
                    }
                })
            });
            return finalWebContentItem;
        }

        productionSeasons = productionSeasons.map(season => { 
            
            let _productions = productions.filter(production => production.ProductionSeason.Id == season.ProductionSeasonId);

            _productions = _productions.map((singleProduction) => {
                const performanceId = singleProduction.PerformanceId;
                return ({
                    production: singleProduction,
                    webcontent: getWebContent(performanceId)
                });
            });
            
            let _keywords = keywords.filter( keyword => keyword.RequestedOwner.ElementId == season.PerformanceId)
                                .map( keyword => keyword.Keywords )[0];
                                                                
            return { 
            ...season,
                keywords: _keywords,
                // webcontent: _webcontent,
                productions : _productions,
                productionsKeywordIds: _.flattenDeep(_productions.map(p => p.keywordIds))
            } 
        });

        keywords = _.uniqBy(_.flattenDeep(keywords.map( k => k.Keywords )), k => k.Id).map(k => k);


        
        // this.setState({ 
        //     productionSeasons: productionSeasons,
        //     keywords: keywords,
        //     productions: productions
        // });
    };

    selectKeyword(id) {
        let selectedIds = _.cloneDeep(this.state.selectedKeywords)
        let index = selectedIds.indexOf(id);
        if (index  > -1 ) {
            selectedIds.splice(index, 1);
        } else {
            selectedIds.push(id);
        }

        this.setState({
            selectedKeywords: selectedIds
        })
    }

    async componentDidMount() {
        await this.getData();
    };

    handleShowMore = () => {
        this.setState({showValue: this.state.showValue += 10})
    }

    handleSearch = (e) => {
        const $self = e.target;
        if (!!$self.value) $self.classList.add('has-content');
        else {
            this.setState({showValue: 2})
            $self.classList.remove('has-content')
        };
        this.setState({searchText: e.target.value.toLowerCase()})
    }

    render() {
        let index = -1; // Use for total item without search
        let searchItemCount = -1; // Use for total search item and display it
        let propKeywords = this.props.keywordIds.split(',').map( k => parseInt(k));

        // Get total cound of each ProductionSeasionID
        let totalCount = 0;
        if(!!!this.state.searchText) {
            let arrCount = this.state.productionSeasons.map((ps, key) => {
                return ps.productions.filter(
                    p => (this.state.selectedKeywords.length == 0 || _.intersection(this.state.selectedKeywords, p.production.keywordIds).length > 0) 
                            && _.intersection(propKeywords , p.production.keywordIds).length > 0
                    ).length
            })
            
            arrCount.map(count => {
                totalCount += count;
            })
            // console.log({totalCount});
        }

        

        return this.state.productionSeasons.length == 0 ? (<div style={{textAlign:"center", width: '80px', margin: '0 auto'}}>
                <img src={loading} alt="loading..." />
                <h3>Loading...</h3>
            </div>) : (<section className="education_listing performances">
            <header className="performance-header container">
                <div className="edu-search">
                    <input type='text' name="edu-search" className="form-input" onChange={this.handleSearch}/>
                    <label className="form-label">Search</label>
                    <span className="focus-border"></span>
                </div>
                <div className="filter-group">
                    <PerformanceFilter initialFilters="" applyFilters={this.applyFilters} activeFilters={this.props.activeFilters}/>
                </div>
            </header>

            <div className="productionSeason">
                {this.state.productions
                    .filter(p => {
                        // remove all items that do not have a keyword
                        return !!p.keywordIds && p.keywordIds.length > 0;
                    })
                    .filter( p => {
                    // filter for keywords
                    if (this.state.selectedKeywords.length === 0) {
                        return true;
                    }
                    return _.intersection(this.state.selectedKeywords, p.keywordIds).length > 0;

                }).sort((a,b) => {
                    let aD = new Date(a.PerformanceDate);
                    let bD = new Date(b.PerformanceDate);
                    if (aD < bD) {
                        return -1;
                    }
                    if (aD > bD) {
                        return 1;
                    }
                    // a must be equal to b
                    return 0;
                }).map(p=> {
                    let educationTitle = p.PerformanceDescription.split(':')[0];
                    let description = p.ProductionSeason.Description;
                    let educationDate = moment.tz(p.PerformanceDate, 'America/Denver').format('MMMM Do, YYYY');
                    let education = {
                        fields : {
                            featuredfields: "", 
                            backgroundColor: "dark-hot-pink", 
                            educationTitle: educationTitle,
                            description: description,
                            educationDate: `${educationDate}`
                        },
                        learnMore: null, 
                        buyTickets: { href: `${constants.ArvadaSupportUrl}/${p.ProductionSeason.Id}/${p.PerformanceId}`, target: '_blank', text: 'Buy Tickets' }
                    }

                    // Search Logic
                    if(!!this.state.searchText && 
                        ( educationTitle.toLowerCase().indexOf(this.state.searchText) > -1 || 
                        description.toLowerCase().indexOf(this.state.searchText) > -1 || 
                        educationDate.toLowerCase().indexOf(this.state.searchText) > -1 )) 
                        {
                            searchItemCount++;
                            if(searchItemCount < this.state.showValue) return <PerformanceItem item={education} />
                    }
                    if(!!this.state.searchText) {
                        return;
                    }

                    index++;
                    if(index < this.state.showValue) {
                        return <PerformanceItem item={education} />
                    }
                    return;
                }
            )}
            </div>
            {(
                // Detect for none searchText
                // Detect for searchText
                // If has searchText AND showValue bigger then totalCount 
                (!!!this.state.searchText && !!index && index > -1 && index >= this.state.showValue) || 
                (!!this.state.searchText && !!searchItemCount && searchItemCount > -1 && searchItemCount >= this.state.showValue) || 
                (!!!this.state.searchText && !(this.state.showValue >= totalCount))) && 
                <div className="loadmore container">
                    <button onClick={this.handleShowMore}>
                        LOAD MORE
                        <img src={loadmoreIcon} alt="load more" />
                    </button>
                </div>
            }
        </section>)
    };
}

export default EducationListing;