import Accordion from './Accordion/Accordion';
import AgilityFormBuilder from './AgilityFormBuilder/AgilityFormBuilder';
import Button from './Button/Button';
import BlogListing from './BlogListing/BlogListing';
import BlogDetail from './BlogDetail/BlogDetail';
import BlogSlider from './BlogSlider/BlogSlider';
import CardSlider from './CardSlider/CardSlider';
import Heading from './Heading/Heading';
import Hero from './Hero/Hero';
import ImageGallery from './ImageGallery/ImageGallery';
import PerformanceDetails from './PerformanceDetails/PerformanceDetails';
import PerformanceFilter from './PerformanceFilter/PerformanceFilter';
import PerformanceHero from './PerformanceHero/PerformanceHero';
import PerformanceListing from './PerformanceListing/PerformanceListing';
import Performers from './Performers/Performers';
import Raw from './Raw';
import RichTextArea from './RichTextArea/RichTextArea';
import TextWithAside from './TextWithAside/TextWithAside';
import TextWithAsideRuntime from './TextWithAsideRuntime/TextWithAsideRuntime';
import TextWithForm from './TextWithForm/TextWithForm';
import TextWithImage from './TextWithImage/TextWithImage';
import TextWithTwoButton from './TextWithTwoButton/TextWithTwoButton';
import PageAlert from './SiteWideAlert/SiteWideAlert'
import EducationListing from './EducationListing/EducationListing';

const AgilityModules = {
    Accordion,
    AgilityFormBuilder,
    Button,
    BlogListing,
    BlogDetail,
    BlogSlider,
    CardSlider,
    EducationListing,
    Heading,
    Hero,
    ImageGallery,
    PerformanceDetails,
    PerformanceHero,
    PerformanceFilter,
    PerformanceListing,
    Performers,
    Raw,
    RichTextArea,
    PageAlert,
    TextWithAside,
    TextWithAsideRuntime,
    TextWithForm,
    TextWithImage,
    TextWithTwoButton,
};

export default AgilityModules;
