const scrollTop = () => (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

export const bottomJumplinkAnimation = () => {
    const windowScroll = scrollTop();
    const heroHeight = document.getElementsByClassName('Masthead')[0].offsetHeight;
    const breadcrumbHeight = document.getElementsByClassName('breadcrumb')[0].offsetHeight;
    //const jumplinksHeight = document.getElementsByClassName('jumplinks')[0].offsetHeight;
    const siteHeaderNav = document.getElementsByClassName('site-header-nav')[0].offsetHeight;
    // const totalHeight = heroHeight + breadcrumbHeight - siteHeaderNav;
    const totalHeight = heroHeight * 0.75 - siteHeaderNav;
    const bottomJumplink = document.getElementsByClassName('bottomJumplink')[0];

    bottomJumplink.style.top = `${siteHeaderNav}px`;

    bottomJumplink.classList.remove('active');

    if (windowScroll >= totalHeight) bottomJumplink.classList.add('active');
};
