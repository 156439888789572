import React, {useEffect} from 'react';
import './agilityFormBuilder.scss'

const AgilityFormBuilder = (props) => {
    const formFormat = [ 
        'text', 'textarea', 'number', 'number', 'date', 'file', '', '', '', 'checkbox', 'select', '', 'email'
    ]
    let formData = JSON.parse(props.additionalJSON);
    let rnd = Math.floor(Math.random() * Math.floor(9999));
    const formRef = React.useRef();

    const handleInputChange = e => {
        const $self = e.target;
        if (!!$self.value) $self.classList.add('has-content');
        else $self.classList.remove('has-content');
    };

    

    const _onSubmit = (e) => {
        e.preventDefault();
        console.log(formRef.current);
        console.log(new FormData(formRef.current));
        // let record = {RecordTypeName: "TextWithForm"};
        // formData.map((field) => {
        //     record[field.Name] = "213";
        // })
        // console.log({record});
        
        const rawFormSchema = JSON.parse(atob(props.textblob));

        const formData = new FormData(e.target);

        console.log(formData);

        let formSchema;
        // get the formschema by recordid 
        _ugcClient.GetRecordType(props.recordTypeID, (response) => {
            formSchema = response.ResponseData;
        });

        console.log(formSchema);

        // Tetsing Submit form for Audition contact subscription only
        var record = {
            RecordTypeName: 'ArtistSubmission', 
            EmailAddress: "lirothtana@dotfusion.com",
            FirstName: "Lirothtana",
            LastName: "Leam"
        };
        // _ugcClient.SaveRecord(record, function(data) {	
        //     if (data.ResponseType != _ugcClient.ResponseType.OK) {
        //         //error occurred
        //         alert("An error occurred: " + data.Message);			
        //     } else {
        //         //data.ResponseData is the recordID of the saved record.
        //         var recordID = data.ResponseData;		
        //         alert("success");
        //     }
        // });
    }
    
    
    
    return (
        <section className={`agilityFormBuilder ${!!props.isNotFormNormal ? "" : "container normal-form"}`} style={{
            // backgroundColor: props.formBackgroundColor, 
            // fontFamily: props.formFontFamily,
            // padding: props.formPadding,
            // margin: props.formMargin,
            // border: props.formBorder,
            // borderRadius: props.formBorderRadius
        }}>
            <form id="xxxx" ref={formRef} className="form" onSubmit={_onSubmit}>
                {formData.map((form, i) => {
                    if(!!formFormat[form.DataType] && (form.DataType != 1 && form.DataType != 10)){
                        let className = formFormat[form.DataType] == "checkbox" ? 'form-input-checkbox' : (formFormat[form.DataType] == "file" ? "form-input-file" : 'form-input')
                        return (
                            <div className={`form-group ${form.ColumnWidth} form-type-${formFormat[form.DataType]}`} key={i}>
                                <input className={className} onChange={handleInputChange} type={formFormat[form.DataType]} id={`${form.Name.toLowerCase()}-${rnd}`} name={form.Name} placeholder={form.Placeholder}/>
                                <label className="form-label" htmlFor={`${form.Name.toLowerCase()}-${rnd}`}>{form.Name}</label>
                                <span className="focus-border"></span>
                            </div>
                        )
                    } else if(!!formFormat[form.DataType]) {
                        if(formFormat[form.DataType] == 'textarea') {
                            return (
                                <div className={`form-group ${form.ColumnWidth} form-type-${formFormat[form.DataType]}`} key={i}>
                                    <textarea className="form-textarea" rows="5" name={form.Name} onChange={handleInputChange} placeholder={form.Placeholder}></textarea>
                                    <label className="form-label">{form.Name}</label>
                                    <span className="focus-border"></span>
                                </div>
                            )
                        } else if (formFormat[form.DataType] == 'select') {
                            return (
                                <div className={`form-group ${form.ColumnWidth} form-type-${formFormat[form.DataType]}`} key={i}>
                                    <label className="form-label">{form.Name}</label>
                                    <select className="form-select" name={form.Name} placeholder={form.Placeholder}>
                                        <option></option>
                                    </select>
                                </div>
                            )
                        }
                    } else {
                        return (
                            <div className={`form-group ${form.ColumnWidth}`} key={i}>
                                <label>{form.Name}</label>
                                <br/><small>Undefined datatype of this field!</small>
                            </div>
                        )
                    }
                })}
                <div className='form-group mb-0'>
                    <button type="submit" style={{
                        // backgroundColor: props.buttonBackgroundColor,
                        // color: props.buttonFontColor,
                        // fontSize: props.buttonFontSize,
                        // fontWeight: props.buttonFontWeight,
                        // height: props.buttonHeight,
                        // width: props.buttonWidth,
                        // margin: props.buttonMargin,
                        // padding: props.buttonPadding,
                        // border: props.buttonBorder,
                        // borderRadius: props.buttonBorderRadius
                    }}>{props.submitButtonLabel}</button>
                </div>
            </form>
        </section>
    )
}

export default AgilityFormBuilder;