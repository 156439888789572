import React, { useState, useEffect } from 'react';
import { DayPickerRangeController } from 'react-dates';
import { useSpring, animated } from 'react-spring';
import moment from 'moment';
import {customNavNext, customNavPrev} from './CalendarHelper';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './CalendarButton.scss';

const CalendarButton = ({ onDatesChange }) => {
    const [isShow, setIsShow] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState('startDate');
    const fadeStyle = useSpring({ opacity: isShow ? 1 : 0 });
    const today = moment().startOf('day');

    const handleFocusChange = focused => setFocusedInput(focused || 'startDate');
    const handleDatesChange = ({ startDate, endDate }) => {
        setStartDate(startDate);
        setEndDate(endDate);
    };
    const handleCalendarClick = () => {
        setIsShow(!isShow);
    };

    useEffect(() => {
        if (!!startDate || !!endDate) {
            let rangeDate = { startDate, endDate };

            // onDatesChange return
            // => rangeDate: { startDate: Moment, endDate: Moment }
            onDatesChange(rangeDate);
        }
    }, [startDate, endDate]);

    return (
        <div className="calendar-button">
            <button onClick={handleCalendarClick} className={`btn-calendar ${isShow ? 'show' : ''}`}>
                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <title>calendar</title>
                    <desc>Created with Sketch.</desc>
                    <g id="------" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="(m)-component-guide" transform="translate(-25.000000, -5163.000000)" fill="#400099" className="calendar-icon">
                            <g id="Group-24" transform="translate(0.000000, 5076.000000)">
                                <g id="Group-10" transform="translate(25.000000, 87.000000)">
                                    <g id="calendar">
                                        <path d="M20.3400011,1.8000001 L19.2600011,1.8000001 L19.2600011,0 L17.460001,0 L17.460001,1.8000001 L5.5800003,1.8000001 L5.5800003,0 L3.78000021,0 L3.78000021,1.8000001 L2.70000015,1.8000001 C1.21122007,1.8000001 0,3.01122016 0,4.5 L0,20.3400011 C0,21.8287812 1.21122007,23.0400013 2.70000015,23.0400013 L20.3400011,23.0400013 C21.8287812,23.0400013 23.0400013,21.8287812 23.0400013,20.3400011 L23.0400013,4.5 C23.0400013,3.01122016 21.8287812,1.8000001 20.3400011,1.8000001 Z M21.2400012,20.3400011 C21.2400012,20.8362611 20.8362611,21.2400012 20.3400011,21.2400012 L2.70000015,21.2400012 C2.20374012,21.2400012 1.8000001,20.8362611 1.8000001,20.3400011 L1.8000001,8.46000046 L21.2400012,8.46000046 L21.2400012,20.3400011 Z M21.2400012,6.66000036 L1.8000001,6.66000036 L1.8000001,4.50000025 C1.8000001,4.00374022 2.20374012,3.6000002 2.70000015,3.6000002 L3.78000021,3.6000002 L3.78000021,5.4000003 L5.5800003,5.4000003 L5.5800003,3.6000002 L17.460001,3.6000002 L17.460001,5.4000003 L19.2600011,5.4000003 L19.2600011,3.6000002 L20.3400011,3.6000002 C20.8362611,3.6000002 21.2400012,4.00374022 21.2400012,4.50000025 L21.2400012,6.66000036 Z" id="Shape"></path>
                                        <rect id="Rectangle" x="3.42000019" y="10.3500006" width="1.8000001" height="1.8000001"></rect>
                                        <rect id="Rectangle" x="7.02000038" y="10.3500006" width="1.8000001" height="1.8000001"></rect>
                                        <rect id="Rectangle" x="10.6200006" y="10.3500006" width="1.8000001" height="1.8000001"></rect>
                                        <rect id="Rectangle" x="14.2200008" y="10.3500006" width="1.8000001" height="1.8000001"></rect>
                                        <rect id="Rectangle" x="17.820001" y="10.3500006" width="1.8000001" height="1.8000001"></rect>
                                        <rect id="Rectangle" x="3.42000019" y="13.9500008" width="1.8000001" height="1.8000001"></rect>
                                        <rect id="Rectangle" x="7.02000038" y="13.9500008" width="1.8000001" height="1.8000001"></rect>
                                        <rect id="Rectangle" x="10.6200006" y="13.9500008" width="1.8000001" height="1.8000001"></rect>
                                        <rect id="Rectangle" x="14.2200008" y="13.9500008" width="1.8000001" height="1.8000001"></rect>
                                        <rect id="Rectangle" x="3.42000019" y="17.550001" width="1.8000001" height="1.8000001"></rect>
                                        <rect id="Rectangle" x="7.02000038" y="17.550001" width="1.8000001" height="1.8000001"></rect>
                                        <rect id="Rectangle" x="10.6200006" y="17.550001" width="1.8000001" height="1.8000001"></rect>
                                        <rect id="Rectangle" x="14.2200008" y="17.550001" width="1.8000001" height="1.8000001"></rect>
                                        <rect id="Rectangle" x="17.820001" y="13.9500008" width="1.8000001" height="1.8000001"></rect>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </button>

            <animated.div className="DayPickerRangeWrapper" style={fadeStyle}>
                <DayPickerRangeController
                    hideKeyboardShortcutsPanel
                    daySize={31}
                    startDate={startDate}
                    endDate={endDate}
                    focusedInput={focusedInput}
                    onFocusChange={handleFocusChange}
                    onDatesChange={handleDatesChange}
                    isOutsideRange={day => day.isBefore(today)}
                    navNext={customNavNext}
                    navPrev={customNavPrev}
                />
            </animated.div>
        </div>
    );
};

export default CalendarButton;
