import React, { useEffect, useState } from 'react';
import { CarouselProvider, Dot, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './PerformanceSlider.scss';
import ResponsiveVideo from './ResponsiveVideo';

const PerformanceSlider = ({items, fallbackAlt, display, dimensions, isImage = true, defaultSlideWidth = 640, sumPadding = 20}) => {
    const [slide, setSlide] = useState(0);
    const [activeSlide, setActiveSlide] = useState(display);
    const breakpoint = {
        xs: 0,
        sm: 544,
        md: 768,
        lg: 992,
        xl: 1200
    }
    const [naturalSlideWidth, setNaturalSlideWidth] = useState(dimensions.desktopSlideWidth);
    const [naturalSlideHeight, setNaturalSlideHeight] = useState(dimensions.desktopSlideHeight);
    const [slideWidth, setSlideWidth] = useState(defaultSlideWidth);
    useEffect(() => {
        if(window.screen.width < breakpoint.lg) {
            setActiveSlide(1);
            setNaturalSlideWidth(dimensions.mobileSlideWidth);
            setNaturalSlideHeight(dimensions.mobileSlideHeight);
            setSlideWidth(dimensions.mobileSlideWidth + sumPadding)
        } else {
            setNaturalSlideWidth(dimensions.desktopSlideWidth);
            setNaturalSlideHeight(dimensions.desktopSlideHeight);
            setSlideWidth(defaultSlideWidth)
        }
        window.addEventListener('resize', (e) => {
            if(e.currentTarget.innerWidth < breakpoint.lg) {
                setActiveSlide(1);
                setNaturalSlideWidth(dimensions.mobileSlideWidth);
                setNaturalSlideHeight(dimensions.mobileSlideHeight);
                setSlideWidth(dimensions.mobileSlideWidth + sumPadding)
            } else {
                setActiveSlide(display);
                setNaturalSlideWidth(dimensions.desktopSlideWidth);
                setNaturalSlideHeight(dimensions.desktopSlideHeight);
                setSlideWidth(defaultSlideWidth)
            }
        })
    }, [naturalSlideWidth, naturalSlideHeight, activeSlide]);

    // console.log(items);
    return (<section className="performance-slider">
        <CarouselProvider
        visibleSlides={activeSlide}
        totalSlides={items.length}
        step={activeSlide}
        naturalSlideWidth={naturalSlideWidth}
        naturalSlideHeight={naturalSlideHeight}
        currentSlide={slide}
        >
            <div className="container">
                <div className="slide-button-container">
                    <ButtonBack>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path fill="currentColor" d="M136.97 380.485l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L60.113 273H436c6.627 0 12-5.373 12-12v-10c0-6.627-5.373-12-12-12H60.113l83.928-83.444c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0l-116.485 116c-4.686 4.686-4.686 12.284 0 16.971l116.485 116c4.686 4.686 12.284 4.686 16.97-.001z"></path>
                        </svg>
                    </ButtonBack>
                    <ButtonNext>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path fill="currentColor" d="M311.03 131.515l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887l-83.928 83.444c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l116.485-116c4.686-4.686 4.686-12.284 0-16.971L328 131.515c-4.686-4.687-12.284-4.687-16.97 0z"></path>
                        </svg>
                    </ButtonNext>
                </div>
            </div>
            <div className="container">
                <div className="hidden">
                    <Slider className="slider opacity" style={{overflow: 'initial', width: `${slideWidth}px`}}>
                        {items.map( (item, i) => {
                            return (<Slide key={i} index={i}>
                                <div className="card">
                                    <div className="card-image">
                                        {!!item.metaData && !!item.metaData.Title && <div className="card-image-title">{item.metaData.Title}</div>}
                                        {
                                            isImage ? 
                                            <img alt={!!item.metaData && !!item.metaData.Title ? item.metaData.Title : fallbackAlt } src={`${item.url}?w=640`} width={naturalSlideWidth} height={naturalSlideHeight}/> 
                                            :
                                            (<div style={{width: `${naturalSlideWidth}px`, height: `${naturalSlideHeight}px`}}>
                                                <ResponsiveVideo url={item.fields.youTubeURL} />
                                            </div>)
                                        }
                                    </div>
                                </div>
                            </Slide>)
                        })}
                    </Slider>
                </div>
            </div>
            <div className="container">
                <div className="dots-container">
                    {items.map( (item, i) => {
                        return <Dot key={i} slide={i}></Dot>
                    })}
                </div>
            </div>
        </CarouselProvider>
</section>)
}

export default PerformanceSlider;