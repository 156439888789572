import React from 'react'
import CardSlider from './../CardSlider/CardSlider'
import './BlogSlider.scss'

const BlogSlider = ({blogs}) => {
    return (<section className="blog-slider">
        {!!blogs.items && <CardSlider items={blogs.items} defaultActiveSlide={2} />}
    </section>)
}

export default BlogSlider