import React from 'react';
import { useTrail, animated, config } from 'react-spring';

const SearchResult = ({ searchResults }) => {
    const trail = useTrail(searchResults.length, {
        opacity: 1,
        x: 0,
        from: { opacity: 0, x: 10 },
        config: config.default,
    });

    return (
        <React.Fragment>
            {trail.map(({ x, ...rest }, index) => (
                <animated.div key={index} style={{ ...rest, transform: x.interpolate(x => `translate3d(0,${x}px,0)`) }}>
                    <a href={searchResults[index].Url} key={index}>
                        {searchResults[index].Title}
                    </a>
                </animated.div>
            ))}
        </React.Fragment>
    );
};

export default SearchResult;
