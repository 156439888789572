import axios from 'axios';
const base64 = require('base-64');
const user = "RESTDEV:Web:TNEW_WEB_ADMIN"; // Tessitura Username
const password = "Center20"; // Tessitura Password

const credential = `${user}:${password}`; // Merge Tessitura Username and Password
const Authorization = base64.encode(credential); // Convert Tessitura Credential to base64
const tessituraApi = axios.create({
  // baseURL: 'http://arvaapp.tessituranetworkramp.com/LiveAPI/TessituraService/',
  baseURL: 'https://ARVADAUSCO0restprod.tnhs.cloud/tessituraservice',
  timeout: 30000,
  headers: {
    Authorization: `Basic ${Authorization}`
  }
});

export {Authorization}
export default tessituraApi;