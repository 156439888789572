import React from 'react';
import AgilityModules from './AgilityModules'
import _ from 'lodash';

const ZoneRenderer = ({zone, zoneName}) => {
  // GROUP BY GRADIENTS
  let gIndex = 0;
  !!zone && zone.forEach((module) => {
    if (module.module == "GradientStart" || 
        module.module == "GradientEnd" || 
        module.module == "SectionTextWithFormStart" || 
        module.module == "SectionTextWithFormEnd") {
      gIndex++;
    }
    module.group = gIndex;
  });
  let groupedZones = _.groupBy(zone, module => module.group);
  let groupedArray = [];
  for (const property in groupedZones) {
    groupedArray.push(groupedZones[property]);
  }

  // Set gradient properties and remove gradient end modules
  groupedArray.forEach((group, index) => {
    if (group[0].module == "GradientStart") {
      group.gradient = true;
    } else if (group[0].module == "GradientEnd") {
      group.splice(0, 1);
    } else if (group[0].module == "SectionTextWithFormStart") {
      group.SectionTextWithForm = true;
    } else if (group[0].module == "SectionTextWithFormEnd") {
      group.splice(0, 1);
    } else {
      group.gradient = false;
      group.SectionTextWithForm = false;
    }
  });

  // Set gradient inline styles
  groupedArray.forEach((group, index) => {
    if (group.gradient && group[0].item.fields.gradientType == "hot-pink") {
      group.gradientType = 'linear-gradient(77deg, #d6006e, #400099)'
    }
    if (group.gradient && group[0].item.fields.gradientType == "red-blue") {
      group.gradientType = 'linear-gradient(77deg, #751c59, #082140)'
    }
    if (group.gradient && !!group[0].item.fields.customGradientStartColor && !!group[0].item.fields.customGradientEndColor) {
      group.gradientType = `linear-gradient(77deg, ${group[0].item.fields.customGradientStartColor}, ${group[0].item.fields.customGradientEndColor})`
    }
  });

  const zoneRender = (group) => {
    return group.map((m, i) => {
      let CurrentComponent = AgilityModules.Raw;
      if (AgilityModules.hasOwnProperty(m.module)) {
          CurrentComponent = AgilityModules[m.module];
          if(!!group.SectionTextWithForm){
            m.item.fields.isNotFormNormal = true;
          }
      }
      return !!m && !!m.item && m.module !== "GradientEnd" && m.module !== "SectionTextWithFormStart" && m.module !== "SectionTextWithFormEnd" && <CurrentComponent className={!!m.noContainer ? '' : 'container'} key={i} componentName={m.module} {...m.item.fields} {...m.item.properties} />
    })
  }

  return (
    <div id="ZONE" className={zoneName}>
      {groupedArray.map((group, index) => {
        return <div 
          className={!!group.gradient && !!group.gradientType ? 'gradientBackground' : (!!group.SectionTextWithForm ? "textWithForm " : "")}
          style={!!group.gradient && !!group.gradientType ? {backgroundImage : group.gradientType, overflow: 'hidden'} : 
          (!!group.SectionTextWithForm ? {backgroundImage : 'linear-gradient(77deg, var(--dark-hot-pink), var(--indigo))'} : {backgroundImage : 'none'})} key={index}>
            
            {!!group.SectionTextWithForm ? 
              ( <div className="container">{ zoneRender(group) }</div> ) : 
              ( <React.Fragment>{ zoneRender(group) }</React.Fragment> )
            }

            {/* {group.map((m, i) => {
              let CurrentComponent = AgilityModules.Raw;
              if (AgilityModules.hasOwnProperty(m.module)) {
                  CurrentComponent = AgilityModules[m.module];
              }
              return !!m && !!m.item && m.module !== "GradientEnd" && m.module !== "SectionTextWithFormStart" && m.module !== "SectionTextWithFormEnd" && <CurrentComponent className={!!m.noContainer ? '' : 'container'} key={i} componentName={m.module} {...m.item.fields} {...m.item.properties} />
            })} */}
        </div>
      })}
    </div>      
  )    
}

export default ZoneRenderer;