const scrollTop = () => (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

export const headerAnimation = () => {
    const $windowScrollTop = scrollTop();
    const $heroHeight = document.getElementsByClassName('hero')[0].offsetHeight;
    let scrollPercent = 0;

    scrollPercent = $windowScrollTop <= $heroHeight ? ($windowScrollTop / $heroHeight).toFixed(2) : 1;

    document.getElementsByClassName('hero-menu-black')[0].style.opacity = scrollPercent >= 0.85 ? 1 : 0;
    document.getElementsByClassName('hero-content')[0].style.opacity = 1 - scrollPercent;
    document.getElementsByClassName('hero-bg-black')[0].style.height = `${$heroHeight}px`;
    document.getElementsByClassName('hero-bg-black')[0].style.opacity = scrollPercent >= 0.85 ? 1 : scrollPercent;
};

export const backgroundVideo = () => {
    const $hero = document.querySelector('.hero');
    const $video = document.querySelector('.background-video');
    const $container = document.querySelector('.iframe-container');

    if (!!!$container) {
        return false;
    }
    
    let finalWidth = 0;
    let finalHeight = 0;
    let windowWidth = $hero.offsetWidth;
    let windowHeight = $hero.offsetHeight;

    let ratio = 16 / 9;
    let windowRatio = windowWidth / windowHeight;

    if (windowRatio < ratio) {
        finalHeight = $video.offsetHeight;
        finalWidth = finalHeight * ratio;
    } else {
        finalWidth = $video.offsetWidth;
        finalHeight = finalWidth / ratio;
    }

    $container.style.height = `${finalHeight}px`;
    $container.style.width = `${finalWidth}px`;
};
