import React from 'react';
import moment from 'moment-timezone';
import constants from './../../helpers/constants';
import _ from 'lodash';
import './PerformanceDateSelectModal.scss';

const PerformanceDateSelectModal = (props) => {
    const seasonItems = !!props.tessitura.season && props.tessitura.season;
    const excludes = !!props.tessitura.excludes && props.tessitura.excludes.map( x => {
        if(!!x.RequestedOwner){
            return x.RequestedOwner.ElementId;
        }
    });
    const today = moment().format();
    const baseUrl = `${constants.ArvadaSupportUrl}/${props.seasonID}/`

    function closeModal() {
        window.location.hash = "";
        document.body.classList.remove('buyTicketModalOpen');
        if(!!props.onClose){ 
            props.onClose(false) 
        }
    }
    let byMonth = false;
    if (!!seasonItems ) {
        byMonth = _.groupBy(seasonItems.map( i => i), (i) => moment.tz(i.Date, 'America/Denver').month());
    }
    
    // Sort Performance 
    Object.keys(byMonth).map( monthNumber => { 
        return byMonth[monthNumber].sort((a, b) => {
            let timeA = new Date(a.Date);
            let timeB = new Date(b.Date);
            return timeA - timeB;
        });
    })

    // Check If No performance can display in each month
    let checkPerformancesOfMonth = [];
    if(!!byMonth) {
        checkPerformancesOfMonth = Object.keys(byMonth).map( monthNumber => {
            return byMonth[monthNumber].length > 0 
            && ( today > moment(byMonth[monthNumber][0].DefaultStartSaleDateTime).format() 
            && today < moment(byMonth[monthNumber][0].DefaultEndSaleDateTime).format() )
            && ( byMonth[monthNumber].filter((item, key) => {
                    // If item.Date is after todays date
                    // And if todays date is after item.DefaultStartSaleDateTime
                    // And if todays date is before item.DefaultEndSaleDateTime 
                    // remove events that are tagged with the 188 id keyword
                    if (moment(item.Date).format() > today
                    && today > moment(item.DefaultStartSaleDateTime).format() 
                    && today < moment(item.DefaultEndSaleDateTime).format() 
                    && excludes.indexOf(item.Id) === -1) {
                        return byMonth[monthNumber];   
                    }
                })
            )
        })
    }

    console.log(byMonth);
    
    return (
        <div className="PerformanceDateSelectModal" id="PerformanceDateSelectModal">
            <div className="BackgroundOverlay" id="BackgroundOverlay" onClick={closeModal}></div>
            <div className="ModalContentBox" id="ModalContentBox">
                {!!!checkPerformancesOfMonth.length && <svg svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.0" width="35px" height="35px" viewBox="0 0 128 128" pace="preserve">
                        <g>
                            <circle cx="16" cy="64" r="16" fill="#d6006e" fill-opacity="1" />
                            <circle cx="16" cy="64" r="14.344" fill="#d6006e" fill-opacity="1" transform="rotate(45 64 64)" />
                            <circle cx="16" cy="64" r="12.531" fill="#d6006e" fill-opacity="1" transform="rotate(90 64 64)" />
                            <circle cx="16" cy="64" r="10.75" fill="#d6006e" fill-opacity="1" transform="rotate(135 64 64)" />
                            <circle cx="16" cy="64" r="10.063" fill="#d6006e" fill-opacity="1" transform="rotate(180 64 64)" />
                            <circle cx="16" cy="64" r="8.063" fill="#d6006e" fill-opacity="1" transform="rotate(225 64 64)" />
                            <circle cx="16" cy="64" r="6.438" fill="#d6006e" fill-opacity="1" transform="rotate(270 64 64)" />
                            <circle cx="16" cy="64" r="5.375" fill="#d6006e" fill-opacity="1" transform="rotate(315 64 64)" />
                            <animateTransform attributeName="transform" type="rotate" values="0 64 64;315 64 64;270 64 64;225 64 64;180 64 64;135 64 64;90 64 64;45 64 64" calcMode="discrete" dur="560ms" repeatCount="indefinite"></animateTransform>
                        </g>
                    </svg> 
                }
                {!!checkPerformancesOfMonth.length && checkPerformancesOfMonth.map((performances, key) => {
                        if(!!performances && !!performances.length) {
                            return (
                                <div className="byMonth" key={key}>
                                    <div className="monthLabel">{moment.tz(performances[0].Date, 'America/Denver').format('MMMM')}</div>
                                    {
                                        performances.filter(performance => performance.Show === undefined || !!performance.Show ).map((performance, key) => (
                                            <a href={baseUrl + performance.Id} target="_blank" className="SinglePerformanceItem button" key={key}>
                                                <div className="datetime-helper">
                                                    {moment.tz(performance.Date, 'America/Denver').hour() <= 18 ? `MATINEE ${performance.HeadDateExtraText}` : `EVENING ${!!performance.HeadDateExtraText ? performance.HeadDateExtraText: ''}` }
                                                </div>
                                                <div className="datetime">
                                                    <span className="date">{moment.tz(performance.Date, 'America/Denver').format('MMM Do, YYYY')}</span>
                                                    <span className="time">{moment.tz(performance.Date, 'America/Denver').format('ddd, h:mm a')}</span>
                                                </div>
                                                <div className="venue">{performance.Facility.Description}</div>
                                            </a>
                                        ))
                                    }
                                </div>
                            )
                        }
                    })
                }
                {!!checkPerformancesOfMonth.length && !checkPerformancesOfMonth.filter(item => {
                        if(!!item) {
                            return true;
                        }
                    }).length && <h2>{/* display some 'no tickets avail' error message  */}</h2>
                }
            </div>
        </div>
    )
}

export default PerformanceDateSelectModal;