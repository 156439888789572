import React, { useState } from 'react';
import scrollToTarget from 'scroll-to-target';
import PerformanceJumlinks from './../PerformanceJumlinks/PerformanceJumlinks';

import './Jumplinks.scss';

const Jumplinks = ({ links }) => {
    const [selected, setSelected] = useState(links[0].item.fields.uID);
    const handleClick = (e, uID) => {
        e.preventDefault();
        if(!!document.getElementById(uID)) {
            setSelected(uID);
            window.history.pushState('', '', `#${uID}`);
            scrollToTarget(`#${uID}`, 300, 100);
        } else {
            console.log(`no uid - ${uID}`)
        }
    };
    const handleChange = e => {
        setSelected(e.target.value);
        window.history.pushState('', '', `#${e.target.value}`);
        scrollToTarget(`#${e.target.value}`, 200, 100);
    };

    return (
        !!links && links.length > 0 && <nav className="jumplinks container">
            <ul className="only-desktop jumplinks-container">
                {links.map(link => {
                    const { uID, text, createJumplink } = link.item.fields;

                    return createJumplink === 'true' ? (
                        <li className="jumplink" key={uID}>
                            <a onClick={e => handleClick(e, uID)} href={`#${uID}`}>{text}</a>
                        </li>
                    ) : null;
                })}
            </ul>
            <select className="only-mobile" onChange={handleChange} name="jumplinks-mobile" id="jumplinks-mobile">
                {links.map(link => (
                    <option key={link.item.fields.uID} value={link.item.fields.uID}>
                        {link.item.fields.text}
                    </option>
                ))}
            </select>
        </nav>
    );
};

export default Jumplinks;
