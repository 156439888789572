import slugify from "@sindresorhus/slugify";
import tessituraApi from '../helpers/tessitura';
import axios from 'axios';
import _ from 'lodash';

const AgilityOptions = {
  agilityMiddlewareOptions : {
    error500: '/errors/500',
    error404: '/errors/404',
    zoneHandlers: {
      MainContent: (zone, currentPage, zoneIndex, api) => {
        return zone;
      },
      Masthead: (zone, currentPage, zoneIndex, api) => {
        return zone;
      }
    },
    moduleHandlers: {
      Accordion: async (agilityModule, moduleIndex, zone, currentPage, api, sitemap) => {
        if (agilityModule.item.fields.serverRendered == 'true') {
          const accordionList = await api.getContentList(agilityModule.item.fields.accordionItems.referencename);
          agilityModule.item.fields.items = accordionList.items;
          return agilityModule;
        } else {
          agilityModule.item.fields.items = [];
          return agilityModule;
        }
      },
      BlogListing: async (agilityModule, moduleIndex, zone, currentPage, api, sitemap) => {
        const blogposts = await api.getContentList(agilityModule.item.fields.blogPosts.referencename);
        agilityModule.item.fields.blogPosts = [];
        if(!!blogposts) {
          blogposts.items.map((item, i) => {
            Object.keys(sitemap).map(s => {
              if(sitemap[s].contentID == item.contentID) {
                blogposts.items[i].fields['path']  = sitemap[s].path;
              }
            })
          })
          agilityModule.item.fields.blogPosts = blogposts.items;
        }
        return agilityModule;
      },
      BlogDetail: async (agilityModule, moduleIndex, zone, currentPage, api, sitemap) => {
        if (!!currentPage.dynamic) {
          agilityModule.item.fields = currentPage.dynamic.fields; 
        }
        return agilityModule;
      },
      BlogSlider: async (agilityModule, moduleIndex, zone, currentPage, api, sitemap) => {
          const blogSlider = await api.getContentList(agilityModule.item.fields.blogs.referencename)
          agilityModule.item.fields.blogs['items'] = !!blogSlider ? blogSlider.items : []
          return agilityModule
      },
      CardSlider: async (agilityModule, moduleIndex, zone, currentPage, api, sitemap) => {
        agilityModule.noContainer = true;
        agilityModule.item.fields = await api.getContentList(agilityModule.item.fields.cards.referencename);
        return agilityModule;
      },
      Performers: async (agilityModule, moduleIndex, zone, currentPage, api, sitemap) => {
        agilityModule.noContainer = true;
        agilityModule.item.fields = await api.getContentList(agilityModule.item.fields.performersList.referencename);
        return agilityModule;
      },
      EducationListing: async (agilityModule, moduleIndex, zone, currentPage, api, sitemap) => {
        const getActiveFilters = async (referencename, skip = 0, take=50, items = [], callback) => {
            let contentList = await api.getContentList(referencename, skip, take);
            let totalCount = contentList.totalCount;
            skip += take;
            items = items.concat(contentList.items);
            if(skip < totalCount) {
              return await getActiveFilters(referencename, skip, take, items, callback);
            }
            return {items: items};
        }

        const transformFilters = async () => {
            const activeFilters = await getActiveFilters('keyworddictionary');

            let filterGroups = [];
            activeFilters.items.forEach((filter) => {
                filterGroups.push({
                    groupName: filter.fields.group.trim(),
                    filterItems: []
                });
            })
            filterGroups = _.uniqWith(filterGroups, _.isEqual);
            
            filterGroups.forEach((filterGroup) => {
                activeFilters.items.forEach((filter) => {
                    if (filter.fields.group == filterGroup.groupName) {
                        filterGroup.filterItems.push(filter);
                    }    
                });
            });

            filterGroups.forEach((filterGroup) => {
              let emptyArray = [];

              filterGroup.filterItems = _.groupBy(filterGroup.filterItems, x => x.fields.displayLabel);

              let editedFilterItems = [];
              
              for (const property in filterGroup.filterItems) {  
                let displayLabel = property;
                let keywordIDs = [];
                filterGroup.filterItems[property].forEach((item) => {
                  keywordIDs.push(item.fields.keywordID);
                })
                editedFilterItems.push({
                  displayLabel,
                  keywordIDs
                })
              }

              filterGroup.filterItems = editedFilterItems;

              
            });

            //Sort Filters
            await filterGroups.map(group => {
              let tmpFilter = group.filterItems.sort((a, b) => {
                let titleA = a.displayLabel;
                let titleB = b.displayLabel;
                return titleA.localeCompare(titleB);
              })
              group.filterItems = tmpFilter;
            })
            
            return filterGroups;
        }
        const x = await transformFilters();
        agilityModule.item.fields.activeFilters = x;
        return agilityModule;
      },
      PerformanceListing: async (agilityModule, moduleIndex, zone, currentPage, api, sitemap) => {
        // agilityModule.item.fields.performance = await api.getContentList(agilityModule.item.fields.performances.referencename);
        agilityModule.item.fields.sitemap = sitemap;
        
        let activeFilters = [{
          groupName: "Filter List",
          filterItems: []
        }];
        if (!!agilityModule.item && !!agilityModule.item.fields) {
          // console.log(agilityModule.item.fields.selectedPerformances);
            agilityModule.item.fields.selectedPerformances.map((item) => {
              if (!!item) {
                !!item.fields.free && activeFilters[0].filterItems.push({displayLabel: "Free", keywordIDs: ["true"]});
                !!item.fields.eventType && activeFilters[0].filterItems.push({displayLabel: item.fields.eventType.trim(), keywordIDs: [item.fields.eventType.trim()]});
                activeFilters[0].filterItems = _.uniqWith(activeFilters[0].filterItems, _.isEqual);
              }
            })
          }

        agilityModule.item.fields.activeFilters = activeFilters;
        return agilityModule;
      }, 
      PerformanceHero: async (agilityModule, moduleIndex, zone, currentPage, api, sitemap) => {
        if (!!!currentPage.dynamic) {
          // console.log(currentPage);
          return agilityModule;
        }
        agilityModule.item.fields = currentPage.dynamic.fields;
        return agilityModule;
      },
      PerformanceDetails: async (agilityModule, moduleIndex, zone, currentPage, api, sitemap) => {
        if (!!!currentPage.dynamic) {
          return agilityModule;
        }
        agilityModule.item.fields = currentPage.dynamic.fields;
        try {
          agilityModule.item.fields.videos = await api.getContentList(currentPage.dynamic.fields.videos.referencename);
        } catch {}
        try {
          agilityModule.item.fields.performers = await api.getContentList(currentPage.dynamic.fields.performers.referencename);
        } catch {}

        let jumplinks = [];
        jumplinks.push({
          uID: !!agilityModule.item.fields.overviewHeading ? `${slugify(agilityModule.item.fields.overviewHeading)}-${agilityModule.item.contentID}` : "", 
          text: agilityModule.item.fields.overviewHeading, 
          show: true
        })
        jumplinks.push({
          uID: !!agilityModule.item.fields.imagesHeading ? `${slugify(agilityModule.item.fields.imagesHeading)}-${agilityModule.item.contentID}` : "", 
          text: agilityModule.item.fields.imagesHeading, 
          show: !!agilityModule.item.fields.images && !!agilityModule.item.fields.images.media.length && true
        })
        jumplinks.push({
          uID: !!agilityModule.item.fields.videosHeading ? `${slugify(agilityModule.item.fields.videosHeading)}-${agilityModule.item.contentID}` : "", 
          text: agilityModule.item.fields.videosHeading, 
          show: !!agilityModule.item.fields.videos && !!agilityModule.item.fields.videos.items.length && !!agilityModule.item.fields.videos.items.length > 0 && true
        })
        jumplinks.push({
          uID: !!agilityModule.item.fields.castHeading ? `${slugify(agilityModule.item.fields.castHeading)}-${agilityModule.item.contentID}` : "", 
          text: agilityModule.item.fields.castHeading, 
          show:  !!agilityModule.item.fields.performers && !!agilityModule.item.fields.performers.items && !!agilityModule.item.fields.performers.items.length > 0 && true
        })
        jumplinks.push({
          uID: !!agilityModule.item.fields.additionalDetailsHeading ? `${slugify(agilityModule.item.fields.additionalDetailsHeading)}-${agilityModule.item.contentID}` : "", 
          text: agilityModule.item.fields.additionalDetailsHeading, 
          show: !!agilityModule.item.fields.additionalDetails && true
        })
        agilityModule.item.fields.jumplinks = jumplinks;

        let currentURL = Object.keys(sitemap).filter((item, i) => {
          if(currentPage.dynamic.contentID == sitemap[item].contentID) {
            return true;
          }
        });
        
        let breadcrumb = [];
        Object.keys(sitemap).map((item, i) => {
          if(!!currentURL[0] && currentURL[0].indexOf(item) > -1 || item == '/home' || item == '/') {
            breadcrumb.push(sitemap[item])
          }
        })
        agilityModule.item.fields.breadcrumb = breadcrumb;

        agilityModule.item.fields.tessitura = {};
        
        /**
         * Get Specific Performance via PerformanceID
         * @endpoint TXN/Performances/:performanceId
         * @method GET
         * @param performanceId integer
         * @return void
         */
        
        // await tessituraApi.get(`TXN/Performances?performanceIds=&seasonIds=&productionSeasonId=${agilityModule.item.fields.seasonID}`)
        // .then(function (response) {
        //   agilityModule.item.fields.tessitura = {status: 200, data: response.data};
        // })
        // .catch(function (error) {
        //   agilityModule.item.fields.tessitura = {status: error.response.status, message: error.response.statusText};
        // })

        await axios(`http://arvada.azurewebsites.net/api/performance?productionSeasonId=${agilityModule.item.fields.seasonID}`)
        .then((res) => {
          agilityModule.item.fields.tessitura.season = res.data.data;
        })
        .catch(function (error) {
          agilityModule.item.fields.tessitura.season = false;
        });

        if (!!agilityModule.item.fields.tessitura.season) {
          const productionels = agilityModule.item.fields.tessitura.season.map( p => p.Id).join(',');
          await axios(`http://arvada.azurewebsites.net/api/keywords?keywordIds=188&productionElementIds=${productionels}`)
            .then( excludeRes => {
              agilityModule.item.fields.tessitura.excludes =  excludeRes.data.data
            })
            .catch( function(errr) {
              agilityModule.item.fields.tessitura.excludes = false;
            });
        }

        return agilityModule;
      },
      Hero: (agilityModule, moduleIndex, zone, currentPage, api, sitemap) => {
        agilityModule.noContainer = true;
        return agilityModule;
      },
      Heading: (agilityModule, moduleIndex, zone, currentPage, api, sitemap) => {
        currentPage.jumpLinks = currentPage.jumpLinks || [];
        agilityModule.item.fields.uID = `${slugify(agilityModule.item.fields.text)}-${agilityModule.item.contentID}`;
        if (agilityModule.item.fields.createJumplink) {
          currentPage.jumpLinks.push(agilityModule);
        }
        return agilityModule;
      }
    },
  },
  agilitycmsConfig: {
    guid: '9bf81b91', 
    previewKey: 'Development.98a79f9cdcfdc2776b4f4dbe17b35bd6a38f2b9acb845afdc456ebc7f39e2a9d', 
    productionKey: 'Production.730ce335926fd01807df74e79e12c0c7fccc8756edfd35b6ce5190597e139dd9'
  }
}

export default AgilityOptions;