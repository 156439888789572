import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion";

import RichText from "./../RichTextArea/RichTextArea";

import "./Accordion.scss";

const ArvadaAccordion = ({ className, accordionItems, serverRendered, items }) => {
  const [list, setList] = useState(items || []);

  if (!serverRendered) {
    useEffect(() => {
      async function fetchData() {
        const items = await window._arvada.api.getContentList(accordionItems.referencename);
        setList(items.items);
      }

      fetchData();
    }, []);
  }

  return (
    <div className={className}>
      <Accordion className="accordion" allowZeroExpanded={true} allowMultipleExpanded={false}>
        {list.map(item => (
          <AccordionItem key={item.contentID}>
            <AccordionItemHeading>
              <AccordionItemButton>{item.fields.title}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <RichText textblob={item.fields.description} />
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};

export default ArvadaAccordion;
