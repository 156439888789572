import React, {useState, useEffect} from "react";
import scrollToTarget from "scroll-to-target";

import './PerformanceJumlinks.scss';

const Jumplinks = ({links}) => {
  //const [selected, setSelected] = useState(links[0].uID);
  const [offset, setOffset] = useState(100);

  useEffect(() => {
    let siteHeaderNav = document.getElementsByClassName('site-header-nav')[0].offsetHeight;
    let bottomJumplink = document.getElementsByClassName('bottomJumplink')[0].offsetHeight;
    let totalHeight = siteHeaderNav + bottomJumplink + 50;
    setOffset(totalHeight);
  })

  const handleClick = (e, uID ) => {
    e.preventDefault();
    // setSelected(uID);
    if(!!document.getElementById(uID)) {
      window.history.pushState('', '', `#${uID}`);
      scrollToTarget(`#${uID}`, 200, offset);
    } else {
      // console.log(`no uid - ${uID}`);
    }
  }
  const handleChange = (e) => {
    // setSelected(e.target.value);
    window.history.pushState('', '', `#${e.target.value}`);
    scrollToTarget(`#${e.target.value}`, 200, offset);
  }
  return (
    <nav className="jumplinks container">
      <ul className="only-desktop jumplinks-container">
        {
          links.map( link => (
            !!link.show && <li className="jumplink" key={link.uID}>
              <a onClick={ e => handleClick(e, link.uID) } href={`#${link.uID}`}>{link.text}</a>
            </li>
          ))
        }
      </ul>
      <select className="only-mobile" onChange={ e => handleChange(e) } name="jumplinks-mobile" id="jumplinks-mobile">
        {
          links.map( link => (
            !!link.show && <option key={link.uID} value={link.uID}>{link.text}</option>
          ))
        }
      </select>
    </nav>
  )
}

export default Jumplinks;