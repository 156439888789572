import React from 'react';
import './Image.scss';

const Image = ({url, fullsize}) => {
    const breakpoint = [0, 544, 768, 992, 1200];
    const imageWidth = [544, 768, 992, 1200, 1800];
    return (
        <picture className="image-conponent">
            {
                breakpoint.map((item, i) => (
                    <source media={ 
                        !!breakpoint[i+1] ? 
                        `(min-width: ${item}px) and (max-width: ${breakpoint[i+1]}px)` : 
                        `(min-width: ${item}px)`} 
                    srcSet={!!fullsize ? url : `${url}?w=${imageWidth[i]}`} key={i}/>
                ))
            }
            <img src={!!fullsize ? url : `${url}?w=544`} />
        </picture>
    )
}

export default Image;