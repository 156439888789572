import React, {useState, useEffect} from "react";
import RichTextArea from "./../RichTextArea/RichTextArea";
import PerformanceJumlinks from './../PerformanceJumlinks/PerformanceJumlinks';
import Button from './../Button/Button';
import {bottomJumplinkAnimation} from './bottomJumplinkAnimation';
import { useScroll, useSize, useWindowScroll } from 'react-use';
import scrollToTarget from "scroll-to-target";
import './TextWithAsideRuntime.scss';

const TextWithAsideRuntime = ({content, aside, runtimeAsMinutes, runtimeDescription, links, bottomJumplinkPerformance, breadcrumb, disableRuntimeLabel}) => {
  // let hours = Math.floor(runtimeAsMinutes / 60);
  // let minutes = runtimeAsMinutes;
  // var runtime = hours != 0 ? (hours == 1 ? hours + ' hour ' : hours + ' hours ') : '';
  // runtime += minutes != 0 ? (minutes == 1 ? minutes + ' minute' : minutes + ' minutes') : '';
  const { x, y } = useWindowScroll();
  useEffect(() => {
    bottomJumplinkAnimation();
    document.getElementsByClassName('scrollToTop')[0].classList.add('d-none');
  }, [y])

  const handleScrollTop = () => {
    scrollToTarget('#root', 300, 0);
  }

  const handleBreadcrumbClick = e => {
    if (e.target.classList.value.includes('active')) e.preventDefault();
  };

  return (
    <section className={`textWithAsideRuntime textWithAsideGradient ${!!links && 'pt-0'}`}>
      <nav className="breadcrumb container">
        <ul>
          {
            !!breadcrumb && breadcrumb.map((item, i) => (
              <li key={i}>
                <a href={item.path} className={breadcrumb.length - 1 === i ? 'active' : ''} onClick={handleBreadcrumbClick}>{item.title}</a>
              </li>
            ))
          }
        </ul>
      </nav>
      { !!links && <PerformanceJumlinks links={links}/> }
      
      <article className={`container ${!!links && 'mt-40'}`}>
        <main className="textWithAside-text">
          <RichTextArea textblob={content} />
        </main>
        <aside className="textWithAside-aside">
          <div className="runTime">
            <h6 className="h6">
            {disableRuntimeLabel != 'true' && (<span className="d-inline-flex">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216zm-148.9 88.3l-81.2-59c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h14c6.6 0 12 5.4 12 12v146.3l70.5 51.3c5.4 3.9 6.5 11.4 2.6 16.8l-8.2 11.3c-3.9 5.3-11.4 6.5-16.8 2.6z" className=""></path>
                </svg>
                Runtime
              </span>)}
              { !!runtimeAsMinutes && <span className="times">{ runtimeAsMinutes }</span> }
            </h6>
            <p>{runtimeDescription}</p>
          </div>
          {!!aside && <RichTextArea textblob={aside} /> }
        </aside>
      </article>
      
      <section className="bottomJumplink">
        <div className="container">
          <div className={`nav ${!!!bottomJumplinkPerformance && 'justify-content-end'}`}>
            {!!bottomJumplinkPerformance &&<div className="nav-performance">
              {!!bottomJumplinkPerformance.link && <Button className="buyTickets" url={bottomJumplinkPerformance.link} />}
              <div className="nav-performance-info">
                <h2 className="nav-performance-title">{bottomJumplinkPerformance.title}</h2>
                <p className="nav-performance-date">{bottomJumplinkPerformance.date}</p>
              </div>
            </div>}
            { !!links && <PerformanceJumlinks links={links}/> }
          </div>
        </div>
        <div className="scrollTop" onClick={handleScrollTop}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <path fill="currentColor" d="M35.5 248l148-148.5c4.7-4.7 12.3-4.7 17 0l148 148.5c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L209 156.1V468c0 6.6-5.4 12-12 12h-10c-6.6 0-12-5.4-12-12V156.1L59.6 272c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.6-4.7-12.2 0-16.9zM0 44v8c0 6.6 5.4 12 12 12h360c6.6 0 12-5.4 12-12v-8c0-6.6-5.4-12-12-12H12C5.4 32 0 37.4 0 44z"></path>
          </svg>
        </div>
      </section>
    </section>
  )
}

export default TextWithAsideRuntime;