import agility from '@agility/content-fetch';

const createApi = (guid, apiKey, isPreview=false) => {
  const api = agility.getApi({
    guid: guid,
    apiKey: apiKey,
    isPreview: isPreview
  });
  return {
    api : api,

    getSitemap : items => {
      return Promise.resolve(
        api.getSitemapFlat({
          channelName: 'website',
          languageCode: 'en-us'
        })
          .then(sitemap => sitemap)
          .catch(error => error.data)
      );
    },

    getSitemapNested : () => {
      return Promise.resolve(
        api.getSitemapNested({
          channelName: 'website',
          languageCode: 'en-us'
        })
        .then(sitemap => sitemap)
        .catch(error => error.data)
      )
    },

    getPage : (pageID) => {
      return Promise.resolve(
        api.getPage({
          pageID: pageID,
          languageCode: 'en-us',
          contentLinkDepth: 6
        })
          .then(page => page)
          .catch(error => error.data)
      )
    },
    
    getContentItem : (contentID) => {
      return Promise.resolve(
        api.getContentItem({
          contentID: contentID,
          languageCode: 'en-us',
          contentLinkDepth: 6
        })
          .then(contentItem => contentItem)
          .catch(error => error.data)
      )
    },
    
    getContentList : (referenceName, skip=0, take=50) => {
      return Promise.resolve(
        api.getContentList({
          referenceName: referenceName,
          languageCode: 'en-us',
          take: take,
          skip: skip,
          sort: 'properties.created',
          direction: api.types.SortDirections.ASC
        })
          .then( contentList => contentList )
          .catch(error => error.data)
      );
    }
  }
}

// export 
// module.exports = createApi;
export default createApi;