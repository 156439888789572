import React, { useState, useEffect } from 'react';
import moment from 'moment';

import PerformanceItem from '../PerformanceItem/PerformanceItem';
import CalendarButton from '../CalendarButton/CalendarButton';
import PerformanceFilter from '../PerformanceFilter/PerformanceFilter';
import loadmoreIcon from './load-more-icon.png';

import './PerformanceListing.scss';

// get all keywordIds from fields
// get all prodSeasons and productions with those keywordIds
// get all keyword details for ProductionIds and ProductionSeasonIds
// get all webcontent details fro ProductionIds and ProductionSeasonIds

const PerformanceListing = ({
    // performance,
    selectedPerformanceIDs,
    seasonID,
    performanceIDs,
    activeFilters,
    title,
    sitemap,
    sortOption,
    defaultShowItems = 2,
    selectedPerformances
}) => {
    const [performances, setPerformances] = useState(selectedPerformances);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [showValue, setShowValue] = useState(defaultShowItems);
    const [initialFilters, setInitialFilters] = useState([]);

    const handleCalendarDateChange = rangeDate => {
        setStartDate(rangeDate.startDate);
        setEndDate(rangeDate.endDate);
        setShowValue(defaultShowItems);
    };
    const handleShowMore = () => {
        setShowValue(Number(showValue) + Number(defaultShowItems));
    };

    // useEffect(() => {
    //     setPerformances(performances.filter(p => selectedPerformanceIDList.indexOf(p.contentID) > -1));
    // }, []);

    useEffect(() => {
        let filteredPerformances;

        if (!!startDate || !!endDate) {
            filteredPerformances = selectedPerformances.filter(p => !!p).filter(p => {
                let start = moment(p.fields.startDate);
                let end = moment(p.fields.endDate);
                return (start < startDate || start < endDate) && (end >= startDate || end >= endDate);
            });

            setPerformances(filteredPerformances);
        }
    }, [startDate, endDate]);

    useEffect(() => {
        // Sort Option
        if(!!sortOption && ( sortOption == "by_start_date" || sortOption == "by_end_date" )) {
            let tmp = selectedPerformances.filter(p => !!p && !!p.fields).sort((a, b) => {
                let timeA = sortOption == "by_start_date" ? new Date(a.fields.startDate) : new Date(a.fields.endDate);
                let timeB = sortOption == "by_start_date" ? new Date(b.fields.startDate) : new Date(b.fields.endDate);
                if(timeA.getTime() == timeB.getTime()) {
                    let titleA = a.fields.title;
                    let titleB = b.fields.title;
                    return titleA.localeCompare(titleB);
                }
                return timeA - timeB;
            });
            setPerformances(tmp);
        } else {
            let tmp = selectedPerformances.filter(p => !!p && !!p.fields).sort((a, b) => {
                let timeA = a.fields.title;
                let timeB = b.fields.title;
                return timeA.localeCompare(timeB);
            });
            setPerformances(tmp);
        }
    }, [])
    // const selectedPerformanceIDList = !!selectedPerformanceIDs
    //     ? selectedPerformanceIDs.split(',').map(i => parseInt(i))
    //     : performances.map(p => p.contentID);

    const applyFilters = (filters) => {
        if(!!filters && filters.length > 0) {
            setPerformances(selectedPerformances.filter(item => !!item && !!item.fields).filter((item) => filters.find((filter) => filter == item.fields.eventType || filter == item.fields.free )))
        } else {
            setPerformances(selectedPerformances)
        }
        setInitialFilters(filters);
    }

    return (
        <section className="performances">
            <header className="performance-header container">
                <h3 className="h3 title">{title}</h3>
                <div className="filter-group">
                    <CalendarButton onDatesChange={handleCalendarDateChange} />
                    <PerformanceFilter initialFilters={initialFilters} applyFilters={applyFilters} activeFilters={activeFilters}/>
                </div>
            </header>

            {performances.filter(i => !!i).map((item, i) => {
                    let learnMore = { href: '', target: '_self', text: 'Learn More' };
                    let buyTickets = { href: '#selectDates', target: '_self', text: 'Buy Tickets' };
                    // item.fields.startDate = "2020-01-16T07:00:00+00:00";
                    // item.fields.endDate = "2020-03-29T06:00:00+00:00";
                    let performanceItem = { ...item, buyTickets, learnMore };

                    Object.keys(sitemap).map(keyItem => {
                        if (sitemap[keyItem].contentID === item.contentID) {
                            learnMore.href = keyItem;
                            return;
                        }
                    });

                    if (i < showValue) return <PerformanceItem key={item.contentID} item={performanceItem} />;

                    return;
                })}
            {showValue <= performances.length && (
                <div className="loadmore container">
                    <button onClick={handleShowMore}>
                        LOAD MORE
                        <img src={loadmoreIcon} alt="load more" />
                    </button>
                </div>
            )}
        </section>
    );
};

export default PerformanceListing;
