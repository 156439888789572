import React, { useState, useEffect } from 'react';
import { useSpring, useTrail, animated, config } from 'react-spring';
import arvadaLogo from './arvada-logo.svg';

import './SiteNav.scss';
import SearchResult from './SearchResult';
import loading from './loading.gif';

const SiteNav = ({ showMenu, showSearch, prefix="", globalSiteSettings}) => {
    const [menu, setMenu] = useState();
    const [menuOpen, setMenuOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const menuOverlayStyle = useSpring({ width: menuOpen || searchOpen ? '100%' : '0%' });
    const fadeStyle = useSpring({ opacity: menuOpen || searchOpen ? 1 : 0, delay: 300 });
    const [searchResult, setSearchResult] = useState([]);
    const [isSearchLoading, setIsSearchLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            const sitemap = await window._arvada.api.getSitemapNested();
            setMenu(sitemap);
        }
        fetchData();
    }, []);

    const handleClickSearch = e => {
        setSearchOpen(!searchOpen);
        setMenuOpen(false);
    };
    const handleClickMenu = e => {
        setMenuOpen(!menuOpen);
        setSearchOpen(false);
    };
    const handleClose = e => {
        setMenuOpen(false);
        setSearchOpen(false);
    };
    const handleSearchChange = e => {
        setIsSearchLoading(true);
        let searchText = e.target.value;
        if (!!!searchText) {
            setSearchResult([])
            setIsSearchLoading(false);
        } else if (!!Agility) {
            Agility.Search.Query({
                query: searchText,
                // languageCode: 'en-us', //optional
                // category: 'product', // optional
                // domain: 'https://agilitycms.com', //optional
                top: 10, //optional
                skip: 0, //optional
            })
            .then((t, n, r) => {
                let sitemap = ___appProps.sitemap;
                let searchResult = [];
                Object.keys(sitemap).map(key => {
                    if(!!sitemap[key].title && sitemap[key].title.toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
                        searchResult.push({
                            Title: sitemap[key].title,
                            Url: key
                        })
                    }
                })
                !!t.ResponseData.Results && searchResult.push(...t.ResponseData.Results)
                setSearchResult(searchResult || []);
                setIsSearchLoading(false);
            })
            .catch(error => {
                console.log({ error });
            });
        }
    };

    return (
        <div className="site-nav">
            <button className="sitenav-icon search" onClick={handleClickSearch}>
                <span className="icon sitenav-icon-search" />
            </button>

            <button className="sitenav-icon menu" onClick={handleClickMenu}>
                <label>Menu</label>
                <span className="icon sitenav-icon-menu" />
            </button>

            <animated.div className={`menuOverlay ${menuOpen || searchOpen ? 'show' : ''}`} style={menuOverlayStyle}>
                <animated.section className="menu-overlay-head" style={fadeStyle}>
                    <img src={arvadaLogo} alt="" className="logo" />
                    <button onClick={handleClickSearch} className="sitenav-menu search">
                        <span className="icon sitenav-menu-search" />
                    </button>
                    <button onClick={handleClose} className="sitenav-menu close">
                        {/* <img className="" src={closeIcon} alt="close menu" /> */}
                        <span className="icon sitenav-menu-close" />
                    </button>
                </animated.section>

                <section className="panel-wrapper">
                    <animated.div className={`search-panel ${searchOpen ? 'show' : ''}`} style={fadeStyle}>
                        <input
                            onChange={handleSearchChange}
                            type="text"
                            placeholder="Start typing"
                            autoFocus={searchOpen}
                        />

                        <div className="result">
                            {!!isSearchLoading && <img src={loading} alt="loading..." />}
                            {!!!isSearchLoading && !!searchResult.length && <SearchResult searchResults={searchResult} />}
                        </div>
                    </animated.div>

                    <div className={`menu-panel ${menuOpen ? 'show' : ''}`}>
                        {menu && <SiteMaps prefix={prefix} menu={menu} isMenuShow={menuOpen || searchOpen} />}

                        <animated.footer className="menu-footer" style={fadeStyle}>
                            <h6 className="h6">Follow Us</h6>
                            <section className="social-media">
                                <a href={globalSiteSettings.facebook}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
                                        <path
                                            fill="#4C4C4C"
                                            fillRule="evenodd"
                                            d="M15.49 29H1.6A1.6 1.6 0 0 1 0 27.4V1.6A1.6 1.6 0 0 1 1.6 0h25.8A1.6 1.6 0 0 1 29 1.6v25.8a1.6 1.6 0 0 1-1.6 1.6h-7.39V17.77h3.77l.564-4.377H20.01v-2.794c0-1.267.351-2.131 2.169-2.131l2.317-.001V4.552c-.4-.053-1.776-.172-3.377-.172-3.341 0-5.63 2.04-5.63 5.785v3.228h-3.778v4.377h3.779V29z"
                                            opacity=".401"
                                        />
                                    </svg>
                                </a>
                                <a href={globalSiteSettings.twitter}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="24" viewBox="0 0 29 24">
                                        <path
                                            fill="#4C4C4C"
                                            fillRule="evenodd"
                                            d="M29 2.841a11.683 11.683 0 0 1-3.416.954A6.052 6.052 0 0 0 28.2.444a11.766 11.766 0 0 1-3.78 1.468A5.886 5.886 0 0 0 20.076 0c-3.283 0-5.947 2.713-5.947 6.059 0 .474.05.936.153 1.38-4.945-.254-9.328-2.663-12.264-6.333a6.136 6.136 0 0 0-.805 3.05A6.087 6.087 0 0 0 3.86 9.197a5.872 5.872 0 0 1-2.697-.756v.075c0 2.936 2.051 5.385 4.775 5.94a5.71 5.71 0 0 1-1.568.213c-.383 0-.757-.036-1.12-.107.758 2.406 2.953 4.159 5.558 4.205a11.799 11.799 0 0 1-7.39 2.595c-.48 0-.954-.027-1.419-.083A16.647 16.647 0 0 0 9.12 24c10.945 0 16.928-9.23 16.928-17.238 0-.263-.005-.527-.015-.785A12.161 12.161 0 0 0 29 2.841"
                                            opacity=".401"
                                        />
                                    </svg>
                                </a>
                                <a href={globalSiteSettings.instagram}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29">
                                        <path
                                            fill="#4C4C4C"
                                            fillRule="evenodd"
                                            d="M15.788 0c2.785.005 3.331.025 4.69.087 1.544.07 2.598.316 3.52.674.954.37 1.762.867 2.568 1.673a7.108 7.108 0 0 1 1.673 2.568c.358.922.603 1.976.674 3.52.07 1.546.087 2.04.087 5.978 0 3.938-.017 4.432-.087 5.978-.07 1.544-.316 2.598-.674 3.52a7.108 7.108 0 0 1-1.673 2.568 7.108 7.108 0 0 1-2.568 1.673c-.922.358-1.976.603-3.52.674-1.546.07-2.04.087-5.978.087-3.938 0-4.432-.017-5.978-.087-1.544-.07-2.598-.316-3.52-.674a7.108 7.108 0 0 1-2.568-1.673A7.107 7.107 0 0 1 .76 23.998c-.358-.922-.603-1.976-.674-3.52-.062-1.359-.082-1.905-.086-4.69v-2.576c.004-2.785.024-3.331.086-4.69.07-1.544.316-2.598.674-3.52a7.107 7.107 0 0 1 1.673-2.568A7.107 7.107 0 0 1 5.002.76C5.924.403 6.978.158 8.522.087 9.829.027 10.384.007 12.9.001h2.888zm-.62 2.613h-1.335c-3.264.002-3.756.019-5.192.084-1.414.065-2.182.3-2.693.5A4.492 4.492 0 0 0 4.281 4.28c-.507.507-.822.99-1.085 1.667-.198.511-.434 1.279-.499 2.693-.065 1.436-.082 1.928-.084 5.192v1.334c.002 3.264.019 3.756.084 5.192.065 1.414.3 2.182.5 2.693.262.677.577 1.16 1.084 1.667.507.507.99.822 1.667 1.085.511.198 1.279.434 2.693.499 1.436.065 1.928.082 5.192.084h1.334c3.264-.002 3.756-.019 5.192-.084 1.414-.065 2.182-.3 2.693-.5a4.492 4.492 0 0 0 1.667-1.084c.507-.507.822-.99 1.085-1.667.198-.511.434-1.279.499-2.693.065-1.436.082-1.928.084-5.192v-1.334c-.002-3.264-.019-3.756-.084-5.192-.065-1.414-.3-2.182-.5-2.693a4.492 4.492 0 0 0-1.084-1.667 4.492 4.492 0 0 0-1.667-1.085c-.511-.198-1.279-.434-2.693-.499-1.436-.065-1.928-.082-5.192-.084zM14.5 7.054a7.446 7.446 0 1 1 0 14.892 7.446 7.446 0 0 1 0-14.892zm0 2.613a4.833 4.833 0 1 0 0 9.666 4.833 4.833 0 0 0 0-9.666zm7.74-4.647a1.74 1.74 0 1 1 0 3.48 1.74 1.74 0 0 1 0-3.48z"
                                            opacity=".401"
                                        />
                                    </svg>
                                </a>
                                <a href={globalSiteSettings.youtube}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="21" viewBox="0 0 29 21">
                                        <path
                                            fill="#4C4C4C"
                                            fillRule="evenodd"
                                            d="M11.506 14.373l-.001-8.39 7.835 4.21-7.834 4.18zM28.71 4.53s-.284-2.057-1.153-2.963C26.454.377 25.218.37 24.652.302 20.594 0 14.506 0 14.506 0h-.012S8.406 0 4.348.302c-.567.07-1.802.075-2.906 1.265C.572 2.473.29 4.53.29 4.53S0 6.947 0 9.362v2.266c0 2.416.29 4.832.29 4.832s.283 2.057 1.152 2.963c1.104 1.19 2.553 1.152 3.198 1.277 2.32.23 9.86.3 9.86.3s6.094-.01 10.152-.312c.566-.07 1.802-.075 2.905-1.265.87-.906 1.153-2.963 1.153-2.963s.29-2.416.29-4.832V9.362c0-2.415-.29-4.832-.29-4.832z"
                                            opacity=".401"
                                        />
                                    </svg>
                                </a>
                            </section>
                            <p>
                                Copyright 2019 Arvada Center
                                <br />
                                for the Arts and Humanities
                            </p>
                        </animated.footer>
                    </div>
                </section>
            </animated.div>
        </div>
    );
};

const SiteMaps = ({ menu, isMenuShow, prefix }) => {
    const trail = useTrail(menu.length, {
        opacity: isMenuShow ? 1 : 0,
        x: isMenuShow ? 0 : 10,
        from: { opacity: 0, x: 10 },
        config: config.gentle,
    });

    return (
        <React.Fragment>
            {trail.map(
                ({ x, ...rest }, index) =>
                    menu[index].visible.menu && (
                        <animated.div
                            key={index}
                            className="sitemapItem"
                            style={{ ...rest, transform: x.interpolate(x => `translate3d(0,${x}px,0)`) }}
                        >
                            <a href={prefix+menu[index].path}>
                                <h6 className="h6">{menu[index].title}</h6>
                            </a>

                            {!!menu[index].children && (
                                <div className="sitemapSubitems">
                                    <ul>
                                        {menu[index].children.map((item, index) => item.visible.menu && (
                                            <li key={index}>
                                                <a href={prefix+item.path}>{item.title}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </animated.div>
                    ),
            )}
        </React.Fragment>
    );
};

export default SiteNav;
