import React from "react";

const Heading = ({level, text, displayAs, uID, className}) => {
  const Tag = `${level}`;
  const headingHTML = <Tag id={uID} className={displayAs}>{text}</Tag>;
  return className == 'container' ? 
    <div className="container">{headingHTML}</div> :
    headingHTML;
  ;
}

export default Heading;