import React from 'react';
import moment from 'moment';

import Button from '../Button/Button';

import './TextWithTwoButton.scss';

const TextWithTwoButton = ({
    title,
    startFrom,
    until,
    learnMore = { href: '#', text: 'Learn More' },
    buyTickets = { href: '#', text: 'Buy Tickets' },
    backgroundImage = { url: '' },
    backgroundColor = 'dark-hot-pink',
}) => {
    let _startDate = startFrom.split('T')[0];
    let _untilDate = until.split('T')[0];
    const startDate = moment(_startDate).format('MMMM DD');
    const untilDate = moment(_untilDate).format('MMMM DD, YYYY');
    return (
        <section className="text-with-two-button">
            <div className="mask" style={{ backgroundImage: `url(${backgroundImage.url})` }}></div>
            <div className={`${backgroundColor}`}></div>
            <main className="container">
                <article>
                    <h2 className="h2 h2-indigo font-light">{title}</h2>
                    <p className="p-black">{`${startDate} - ${untilDate}`}</p>
                </article>
                <div className="btn-group">
                    <Button url={learnMore} className="learnMore" />
                    <Button url={buyTickets} className="butTickets" />
                </div>
            </main>
        </section>
    );
};

export default TextWithTwoButton;
