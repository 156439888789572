import React from 'react';
import Sticky from 'react-sticky-el';
import SiteWideAlert from './../SiteWideAlert/SiteWideAlert';
import SiteNav from './../SiteNav/SiteNav';
import scrollToTarget from 'scroll-to-target';

import './SiteHeader.scss';
import Button from '../Button/Button';

const SiteHeader = ({ globalSiteSettings, sitemap, prefix }) => {
    const { siteWideAlert, siteLogo, donate } = globalSiteSettings;

    const handleScrollToTop = e => {
        scrollToTarget('#root', 200, 0);
    };

    return (
        <div className="site-header">
            {siteWideAlert && siteWideAlert.length > 0 && <SiteWideAlert alert={siteWideAlert} />}

            <section className="donate">
                <Button url={donate} className="donate-button" />
            </section>

            <div className="scrollToTop" onClick={handleScrollToTop}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path
                        fill="currentColor"
                        d="M35.5 248l148-148.5c4.7-4.7 12.3-4.7 17 0l148 148.5c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L209 156.1V468c0 6.6-5.4 12-12 12h-10c-6.6 0-12-5.4-12-12V156.1L59.6 272c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.6-4.7-12.2 0-16.9zM0 44v8c0 6.6 5.4 12 12 12h360c6.6 0 12-5.4 12-12v-8c0-6.6-5.4-12-12-12H12C5.4 32 0 37.4 0 44z"
                    ></path>
                </svg>
            </div>

            <Sticky className={`site-header-nav`}>
                <div className="hero-menu-black"></div>
                <header className="wide-container container">
                    <div className="only-mobile">
                        <a className="site-logo" href="/">
                            <img src={siteLogo.url} alt="" />
                        </a>
                        <SiteNav globalSiteSettings={globalSiteSettings}/>
                    </div>

                    <div className="nav-container-desktop">
                        <a className="site-logo" href={prefix + "/"}>
                            <img src={siteLogo.url} alt="Arvada" />
                        </a>
                        <SiteNav prefix={prefix} globalSiteSettings={globalSiteSettings}/>
                    </div>
                </header>
            </Sticky>
        </div>
    );
};

export default SiteHeader;
