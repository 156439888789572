import React from 'react';
import { hydrate } from 'react-dom';
import App from './components/App';
import ugcClient from 'agility-ugc'
import createApi from './helpers/api';
import config from './options/agility';
import SiteNav from './components/App';

// Global settings
window._arvada = {};
window._arvada.prodApi = createApi(config.agilitycmsConfig.guid, config.agilitycmsConfig.productionKey);
window._arvada.previewApi = createApi(config.agilitycmsConfig.guid, config.agilitycmsConfig.previewKey, true);
window._arvada.api = window._arvada.prodApi;
window._ugcClient = ugcClient;

hydrate(<App {...window.___appProps} />, document.getElementById('root'));

if (module.hot) {
  module.hot.accept();
}