import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import Toggle from 'react-toggle';
import './PerformanceFilter.scss';
import Button from '../Button/Button';

const PerformanceFilter = (props) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [accessible, setAccessible] = useState(false);
    const [current, setCurrent] = useState(props.propKeywords);
    const handleFilterMenus = e => {
        setMenuOpen(!menuOpen);
    };
    
    let keywordArray = !!props.initialFilters ? props.initialFilters : [];

    const handleInputToggle = (e, keywordIDs) => {
        e.persist();
        if (!!e.target.checked) {
            // push into keyword array if it does not exist
            keywordIDs.forEach((key) => {
                if (keywordArray.indexOf(key) == -1) {
                    keywordArray.push(key)
                }
            })
        } else {
            // remove from array if they exist
            keywordIDs.forEach((key) => {
                if (keywordArray.indexOf(key) !== -1) {
                    keywordArray.splice(keywordArray.indexOf(key), 1)
                }
            })
        }
    }
    
    const handleApplyFilters = () => {
        setCurrent(keywordArray);
        props.applyFilters(keywordArray);
        setMenuOpen(!menuOpen);
    }
    
    return (
        <div className="performance-filter">

            <button className="filter-button" onClick={handleFilterMenus}>
                <span className="icon filter-icon" />
                FILTERS
            </button>
            <div className={`filter-items ${menuOpen ? 'show' : ''}`}>
                <section className="filter-item-head">
                    <button className="btn-close" onClick={handleFilterMenus}>
                        ×
                    </button>
                </section>
                <section className="filter-item-body">
                    <div className="filter-item-body-head">
                        <span className="icon filter-icon" />
                        FILTERS
                    </div>

                    {!!props.activeFilters && props.activeFilters.map((filter, key) => {

                        return <Accordion key={key} className="accordion mt-35" allowZeroExpanded={true} allowMultipleExpanded={false}>
                            <AccordionItem>   
                                <AccordionItemHeading>
                                    <AccordionItemButton>{filter.groupName}</AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <ul>
                                        {filter.filterItems.map((item, key) => {
                                            return (
                                                <li key={key}>
                                                    <label htmlFor={item.displayLabel.split(' ').join('_')}>
                                                        <input 
                                                        onChange={(e) => handleInputToggle(e, item.keywordIDs)} type="checkbox"
                                                        id={item.displayLabel.split(' ').join('_')}
                                                        name={item.displayLabel.split(' ').join('_')}/>
                                                        <span>{item.displayLabel}</span>
                                                    </label>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    })}
                    <Button onClick={handleApplyFilters} url={{ text: 'apply filters' }} asLink={false} className="mt-35 apply-filters" />
                </section>
            </div>
        </div>
    );
};

export default PerformanceFilter;
