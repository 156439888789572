import React from "react";
import RichTextArea from "./../RichTextArea/RichTextArea";
import './TextWithAside.scss';

const TextWithAside = ({content, aside, className}) => {
  return (<article className={"textWithAside " + className}>
    <main className="textWithAside-text">
      <RichTextArea textblob={content} />
    </main>
    <aside className="textWithAside-aside">
      <RichTextArea textblob={aside} className={ !!aside ? '' : 'no-border' } />
    </aside> 
  </article>)
}

export default TextWithAside;