import React, {useState} from 'react'
import RichTextArea from './../RichTextArea/RichTextArea'
import './BlogDetail.scss'

const BlogDetail = (props) => {
    const {
        title,
        leftText,
        rightText,
        youtube,
        image1,
        image2,

    } = props;

    const [videoId,  setVideoId] = useState(!!youtube ? JSON.parse(youtube) : null)
    console.log({videoId})
    console.log({props})
    return <section className="blog-detail container">
        <h2 className="blog-title">{title}</h2>
        <div className="blog-content">
            <div className="blog-grid">
                <div className="blog-left-text">
                    <RichTextArea textblob={leftText}/>
                </div>
                {!!videoId && <div className="blog-video">
                    <iframe src={`https://www.youtube.com/embed/${videoId.video_id}`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>}
                <div className="blog-right-text">
                    <RichTextArea textblob={rightText} />
                </div>
            </div>
            <div className="blog-images">
                {!!image1 && <div className="blog-image" style={{backgroundImage: `url('${image1.url}')`}} />}
                {!!image2 && <div className="blog-image" style={{backgroundImage: `url('${image2.url}')`}} />}
            </div>
        </div>
    </section>
}

export default BlogDetail;