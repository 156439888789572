import React, { useEffect, useState } from 'react';
import PerformersModal from '../PerformersModal/PerformersModal';
import './Performers.scss';

const User = ({ items }) => {
    const [descriptions, setDescriptions] = useState(items);
    const [display, setDisplay] = useState(false);
    const [description, setDescription] = useState('');

    const handleClick = (e, description) => {
        e.preventDefault();
        setDisplay(true);
        setDescription(description);
    }

    return (
        <section className="performers-section container">
            <main className="performers">
                {
                    items.map((item, i) => {
                        return (
                            <div className="item" key={i}>
                                <div className="card">
                                    <div className="card-image">
                                        {!!item.fields.picture && <img src={item.fields.picture.url+`?w=200`} alt={item.fields.picture.label} />}
                                    </div>
                                    <div className="card-description">
                                        <div>
                                            <h3 className="h5">{item.fields.name}</h3>
                                            <p><strong>Roles:</strong></p>
                                            <p>{item.fields.roles}</p>
                                        </div>
                                        <a role="button" href="#" onClick={(e) => handleClick(e, descriptions[i])}>Read More</a>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </main>
            <PerformersModal data={description} display={display} onDismiss={(d) => setDisplay(d)} />
        </section>
    )
}

export default User;