import React from 'react';
import './PerformanceHero.scss';
import Hero from './../Hero/Hero';
import moment from 'moment';

const PerformanceHero = ({ featuredImage, title, startDate, endDate, seasonID }) => {
    let butTickets = { href: '', target: '_self', text: 'Buy Tickets' };
    let _startDate = startDate.split('T')[0];
    let _endDate = endDate.split('T')[0]; 
    let date = `${moment(_startDate).format('MMMM DD')} - ${moment(_endDate).format('MMMM DD, YYYY')}`;
    if(moment(_startDate).format('YYYY-MM-DD') == moment(_endDate).format('YYYY-MM-DD')) {
        date = moment(_endDate).format('MMMM DD, YYYY');
    }

    return <Hero image={featuredImage} title={title} link={!!seasonID ? {href:'#selectDates', text: 'Buy Tickets'}: {href: "", text: false}} className="hero-small" date={date} />;
};

export default PerformanceHero;
