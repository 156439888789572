import React, { useEffect } from 'react';
import { useWindowScroll, useMeasure, createBreakpoint } from 'react-use';

import { headerAnimation, backgroundVideo } from './heroHelper';
import Button from './../Button/Button';
import YouTube from 'react-youtube';
import './Hero.scss';

const useBreakpoint = createBreakpoint({ xs: 0, lg: 992 });

const Hero = ({ image, link, title, date, className, video }) => {
    const { y } = useWindowScroll();
    const [ref, { width, height }] = useMeasure();
    const breakpoint = useBreakpoint();

    const _className = !!className ? `hero ${className}` : 'hero hero-large';
    let heroContent = <h2 className="hero_title h1">{title}</h2>;
    let ytVideo = !!video ? JSON.parse(video) : '';

    useEffect(() => {
        headerAnimation();
    }, [y]);

    useEffect(() => {
        // if (breakpoint === 'lg') backgroundVideo();
        backgroundVideo();
    }, [width]);

    if (!!className) {
        heroContent = (
            <React.Fragment>
                <h2 className="hero_title h1">{title}</h2>
                <p className="hero_date">{date}</p>
                {!!link && !!link.text && <Button className="hero_link" url={link} />}
            </React.Fragment>
        );
    } else if (!!link) {
        heroContent = (
            <React.Fragment>
                <div className="hot-gradient"></div>
                <h2 className="hero_title h1">{title}</h2>
                <Button className="hero_link" url={link} />
            </React.Fragment>
        );
    }

    const _playVideo = (e) => {
        e.target.mute();
        e.target.playVideo();
    }
    return (
        <div
            ref={ref}
            className={_className}
            style={{
                backgroundImage: `linear-gradient(rgba(0 ,0 ,0 ,0.7), rgba(254 ,254 ,254 ,0), rgba(0 ,0 ,0 ,0.7)), url(${ !!image ? encodeURI(image.url) + "?w=1440" : ''})`,
            }}
        >
            <div className="background-video">
                {!!ytVideo && /*breakpoint === 'lg' && */ (
                    <div className="iframe-container">
                        {/* <iframe
                            title="background video"
                            src={`//www.youtube.com/embed/${ytVideo.video_id}?modestbranding=1&autoplay=1&controls=0&fs=0&iv_load_policy=3&loop=1&rel=0&showinfo=0&disablekb=1&mute=1&playlist=${ytVideo.video_id}`}
                            frameBorder="0"
                        ></iframe> */}
                        <YouTube
                            videoId={ytVideo.video_id}
                            opts={{
                                height: '390',
                                width: '640',
                                playerVars: {
                                    autoplay: 1,
                                    modestbranding: 1,
                                    showinfo: 0,
                                    playsinline: 1,
                                    controls:0
                                }
                            }}
                            onReady={_playVideo}
                            onEnd={_playVideo}
                        />
                    </div>
                )}
            </div>
            <div className="hero-bg-black"></div>
            <div className="hero-content container">{heroContent}</div>
        </div>
    );
};

export default Hero;
