import React, {Component} from 'react';

import './style.scss';

class Raw extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <div className="raw">Component {this.props.componentName} is not available in React's context. It is defined in agility, however. Please check React Dev tools for props.</div>
  }
}

export default Raw;