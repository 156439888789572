import React, { useEffect, useState } from 'react';
import './CardSlider.scss';
import { CarouselProvider, Dot, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

const Cardslider = ({items, defaultActiveSlide = 3}) => {
    const [slide, setSlide] = useState(0);
    const [activeSlide, setActiveSlide] = useState(defaultActiveSlide);
    const breakpoint = {
        xs: 0,
        sm: 544,
        md: 768,
        lg: 992,
        xl: 1200
    }
    useEffect(() => {
        if(window.screen.width < breakpoint.lg) {
            setActiveSlide(1);
        }
        window.addEventListener('resize', (e) => {
            // console.log(e.currentTarget, breakpoint.lg)
            if(e.currentTarget.innerWidth < breakpoint.lg) {
                setActiveSlide(1);
            } else {
                setActiveSlide(defaultActiveSlide);
            }
        })
    }, []);
    return (<div className="card-sliderx">
        <CarouselProvider
        visibleSlides={activeSlide}
        totalSlides={items.length}
        step={activeSlide}
        naturalSlideWidth={400}
        naturalSlideHeight={500}
        currentSlide={slide}
        >
            <div className="container slide-button-container">
                <ButtonBack>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path fill="currentColor" d="M136.97 380.485l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L60.113 273H436c6.627 0 12-5.373 12-12v-10c0-6.627-5.373-12-12-12H60.113l83.928-83.444c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0l-116.485 116c-4.686 4.686-4.686 12.284 0 16.971l116.485 116c4.686 4.686 12.284 4.686 16.97-.001z"></path>
                    </svg>
                </ButtonBack>
                <ButtonNext>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path fill="currentColor" d="M311.03 131.515l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887 239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887l-83.928 83.444c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l116.485-116c4.686-4.686 4.686-12.284 0-16.971L328 131.515c-4.686-4.687-12.284-4.687-16.97 0z"></path>
                    </svg>
                </ButtonNext>
            </div>
            <Slider className="slider container" style={{overflow: 'initial', cursor: 'grab'}}>
                {items.map( (item, i) => {
                    const SlideRange = [slide, slide+3];
                    return (<Slide key={i} index={i}>
                        <a href={item.fields.link.href} className="card">
                            <div className="card-image">
                                {!!item.fields.featuredImage && <img src={item.fields.featuredImage.url} />}
                            </div>
                            <div className="card-description rich-text">
                                <h5>{item.fields.title}</h5>
                                <p>{item.fields.shortDescription}</p>
                                {!!item.fields.phone && <p><b>Phone:</b> {item.fields.phone}</p>}
                                {!!item.fields.link && <a role="button" href={item.fields.link.href} target={item.fields.link.target}>{item.fields.link.text}</a>}
                            </div>
                        </a>
                    </Slide>)
                })}
            </Slider>
            <div className="container dots-container">
                {items.map( (item, i) => {
                    return <Dot key={i} slide={i}></Dot>
                })}
            </div>
        </CarouselProvider>
</div>)
}

export default Cardslider;