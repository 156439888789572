import React, { useState } from 'react';

import RichTextArea from '../RichTextArea/RichTextArea';

import './TextWithForm.scss';

const TextWithForm = ({ content, form, backgroundFirstColor, backgroundSecondColor }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');

    const handleInputChange = e => {
        const $self = e.target;

        if (!!$self.value) $self.classList.add('has-content');
        else $self.classList.remove('has-content');

        switch ($self.name) {
            case 'first-name':
                setFirstName($self.value);
                break;
            case 'last-name':
                setLastName($self.value);
                break;
            case 'email':
                setEmail($self.value);
                break;
            default:
                break;
        }
    };
    const handleFormSubmit = e => {
        e.preventDefault();
        console.log({ firstName, lastName, email });
    };

    return (
        <article className="textWithForm-text">
            <RichTextArea textblob={content} />
        </article>
        // <article className="textWithForm">
        //     <main className="container">
        //         <article className="textWithForm-text">
        //             <RichTextArea textblob={content} />
        //         </article>
        //         <aside className="textWithForm-form">
        //             {/* <form className="form" onSubmit={handleFormSubmit}>
        //                 <div className="form-group input-first-name">
        //                     <input
        //                         name="first-name"
        //                         className="form-input"
        //                         type="text"
        //                         onChange={handleInputChange}
        //                         value={firstName}
        //                     />
        //                     <label className="form-label">First Name</label>
        //                     <span className="focus-border"></span>
        //                 </div>
        //                 <div className="form-group input-last-name">
        //                     <input
        //                         name="last-name"
        //                         className="form-input"
        //                         type="text"
        //                         onChange={handleInputChange}
        //                         value={lastName}
        //                     />
        //                     <label className="form-label">Last Name</label>
        //                     <span className="focus-border"></span>
        //                 </div>
        //                 <div className="form-group input-email">
        //                     <input
        //                         name="email"
        //                         className="form-input"
        //                         type="email"
        //                         onChange={handleInputChange}
        //                         value={email}
        //                     />
        //                     <label className="form-label">Email Address</label>
        //                     <span className="focus-border"></span>
        //                 </div>
        //                 <button type="submit">SUBSCRIBE</button>
        //             </form> */}
        //         </aside>
        //     </main>
        // </article>
    );
};

export default TextWithForm;
