import React from 'react';
import moment from 'moment';

import Button from '../Button/Button';
import PerformanceDateSelectModal from './../PerformanceDateSelectModal/PerformanceDateSelectModal';
import axios from 'axios';

import './PerformanceItem.scss';

class PerformanceItem extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            queryFinished: false
        }
        this.props.item.fields.tessitura = {};
    }

    getModalData = async (seasonID) => {
        this.setState({modal: true, queryFinished: false})
        await axios(`//arvada.azurewebsites.net/api/performance?productionSeasonId=${seasonID}`)
        .then((res) => {
            this.props.item.fields.tessitura.season = res.data.data;
        })
        .catch(function (error) {
            this.props.item.fields.tessitura.season = false;
        });
        
        if(!!this.props.item.fields.tessitura.season.length) {
            let performanceIds = [];
            this.props.item.fields.tessitura.season.map(item => {
                performanceIds.push(item.Id);
            })

            await axios(`//arvada.azurewebsites.net/api/seats?performanceIds=${performanceIds.join(',')}`)
            .then(res => {
                res.data.seats.map((item, i) => {
                    if(item.data[0].AvailableCount > 0) {
                        this.props.item.fields.tessitura.season[i].Show = true;
                        this.props.item.fields.tessitura.season[i].HeadDateExtraText = item.data[0].AvailableCount < 51 ? 'Limited availability' : '';
                    }
                })
            })
            .catch(error => {
                console.log({error})
            })
        }
        if (!!this.props.item.fields.tessitura.season) {
          const productionels = this.props.item.fields.tessitura.season.map( p => p.Id).join(',');
          await axios(`//arvada.azurewebsites.net/api/keywords?keywordIds=188&productionElementIds=${productionels}`)
            .then( excludeRes => {
                this.props.item.fields.tessitura.excludes = excludeRes.data.data;
            })
            .catch( function(errr) {
                this.props.item.fields.tessitura.excludes = false;
            });
        }
        this.setState({queryFinished: true})
    }

    componentDidMount() {
        // this.toggleBuyTicketModal(); 
        if (window.location.hash.slice(1) == "selectDates") { 
            history.pushState("", document.title, window.location.pathname + window.location.search);            
        }
        
        window.addEventListener("hashchange", this.toggleBuyTicketModal);
    }
        
    toggleBuyTicketModal = () => {
        if (window.location.hash.slice(1) == "selectDates") {
            document.body.classList.add('buyTicketModalOpen');
            document.querySelectorAll('.performance>.performances-item').forEach(item => {
                item.classList.add('zindex');
            })
            // if (!!props.tessitura.season && props.tessitura.season.length == 1) {
            //     window.open(`${constants.ArvadaSupportUrl}/${props.seasonID}/${props.tessitura.season[0].Id}?z=1`)
            //     document.body.classList.remove('buyTicketModalOpen');
            //     history.back();
            // }
        } else {
            document.body.classList.remove('buyTicketModalOpen');
            document.querySelectorAll('.performance>.performances-item').forEach(item => {
                item.classList.remove('zindex');
            })
            this.setState({modal: false})
        }
    }

    render(){
        const seasonID = this.props.item.fields.seasonID;
        const { featuredImage, backgroundColor, title, startDate, endDate, educationDate, description, educationTitle } = this.props.item.fields;
        const { learnMore, buyTickets } = this.props.item;
        const backgroundImage = !!featuredImage ? featuredImage.url : '';
        let _startDate = startDate?.split('T')[0] || "";
        let _endDate = endDate?.split('T')[0] || ""; 
        const dateString = !!educationDate ? 
                            educationDate : 
                            moment(_startDate).format('YYYY MMMM DD') == moment(_endDate).format('YYYY MMMM DD') ?
                            moment(_startDate).format('MMMM DD, YYYY') :
                            moment(_startDate).format('MMMM DD') + ' - ' + moment(_endDate).format('MMMM DD, YYYY');
        console.log(educationDate, startDate, endDate);
        return (
            <div className="performance">
                <div className="performances-item">
                    <div className="mask" style={{ backgroundImage: `url(${backgroundImage})` }}></div>
                    <div className={`${backgroundColor}`}></div>
                    <main className="container">
                        <article>
                            {!!description && <h4 className="h2-indigo">{description}</h4>}
                            {!!educationTitle && <h3 className="h4 h2-indigo">{educationTitle}</h3>}
                            {!!title && <h2 className="h2 h2-indigo font-light">{title}</h2> }
                            <p className="p-black">{`${dateString}`}</p>
                        </article>
                        <div className="btn-group">
                            {!!learnMore && <Button url={learnMore} className="learnMore" /> }
                            {!!description && !!educationTitle ? <Button url={buyTickets} className="buyTickets"/>
                                : (!!seasonID && <Button url={buyTickets} className="buyTickets" onClick={() => this.getModalData(seasonID)}/>) }
                        </div>
                    </main>
                </div>
                {!!this.state.modal && <PerformanceDateSelectModal {...this.props.item.fields} onClose={(e) => this.setState({modal: e})}/>}
            </div>
        );
    }
}

export default PerformanceItem;
